import { SERIALIZE_STATE, UPDATE_ENVIRONMENT_CONFIG } from 'constants/reduxActions';
import type { EnvironmentConfigState } from 'types/environment';

type EnvironmentConfigAction =
  | {
      type: typeof SERIALIZE_STATE;
    }
  | {
      type: typeof UPDATE_ENVIRONMENT_CONFIG;
      payload: EnvironmentConfigState;
    };

export default function reducer(state: Readonly<EnvironmentConfigState> = {} as any, action: EnvironmentConfigAction): EnvironmentConfigState {
  switch (action.type) {
    case SERIALIZE_STATE:
      // Strip out server side secrets
      return { ...state, unleashApiKey: undefined };
    case UPDATE_ENVIRONMENT_CONFIG:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
