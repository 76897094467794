import type { QnaItem } from 'components/VisualSearch/VisualSearchResults/visualSearchResults.types';

// TroubleShoot
export const TROUBLESHOOT_HELP_HEADER = 'Troubleshooting Help';
export const TROUBLESHOOT_QNA_RECOGNIZE_ALL_PIECES: QnaItem = {
  question: 'Why does Search By Photo not recognize all pieces in the photo?',
  answer:
    'Search By Photo currently supports clothing and shoes. Search By Photo does not work on handbags, accessories, kids or maternity clothing.'
};
export const TROUBLESHOOT_QNA_INCORRECT_RESULTS: QnaItem = {
  question: 'I don’t get the results I was looking for. What might be going on?',
  answer:
    'Make sure the photo you use captures the entire item you are looking for. We’re continuously striving to provide you with a better experience. If it’s still not working, you may be looking for an item that is currently not supported by Search By Photo.'
};
export const TROUBLESHOOT_QNA_NO_RESULTS: QnaItem = {
  question: 'Why am I getting a network error?',
  answer:
    'Search By Photo needs an internet connection (over wifi or data network) to search. If you can’t access the internet right now, you will get a network error message. Check your network settings and try again.'
};

// Results
export const PHOTO_ZOOM_IN = 'Photo Zoom In';
export const PHOTO_ZOOM_OUT = 'Photo Zoom Out';
export const INPUT_IMAGE_ALT = 'search by photo input';

// Upload
export const SEARCH_BY_PHOTO = 'Search By Photo';
export const SEARCH_BY_IMAGE = 'Search By Image';
export const MOBILE_UPLOAD_PHOTO_DESCRIPTION = 'Take Or Upload A Photo';
export const MAX_ALLOWED_IMAGE_SIZE_IN_MBS = 30;
export const MAX_ALLOWED_IMAGE_SIZE_BYTES = MAX_ALLOWED_IMAGE_SIZE_IN_MBS * 1024 * 1024;

// survey
export const VISUAL_SEARCH_TAKE_SURVEY_TEXT = 'Take Survey';
export const VISUAL_SEARCH_TAKE_SURVEY_LINK = 'https://auscult.zappos.biz/survey/take/search-by-photo-feedback';

// Api
export const IMAGE_MATCH_SEARCH_TIMEOUT_LIMIT_IN_SECONDS = 10;
export const ABORT_ERROR_TEXT = 'AbortError';

// Events
export const PAGE_VIEW = 'PAGE_VIEW';
export const PRODUCT_INTERACTION = 'PRODUCT_INTERACTION';
export const SWATCH_INTERACTION = 'SWATCH_INTERACTION';
export const PRODUCT_CLICK_THROUGH = 'PRODUCT_CLICK_THROUGH';

// About VS
export const ABOUT_VS_QNA_WHAT_IS_SEARCH_BY_PHOTO: QnaItem = {
  question: 'What is Search By Photo?',
  answer:
    'Search By Photo enables you to shop for outfits from photos. You can take photos of the looks you like and upload them to begin shopping for inspiring styles on Zappos.'
};

export const ABOUT_VS_QNA_HOW_TO_USE: QnaItem = {
  question: 'How can I use Search By Photo?',
  answer: 'You can upload a photo or screenshot and discover similar styles on Zappos.'
};

export const ABOUT_VS_QNA_WHY_NOT_RECOGNIZE_ALL_PIECES: QnaItem = {
  question: 'Why does Search By Photo not recognize all pieces in the photo?',
  answer:
    'Search By Photo currently supports clothing and shoes. Search By Photo does not work on handbags, accessories, kids, or maternity clothing.'
};

export const ABOUT_VS_QNA_RESULTS_NOT_AS_EXPECTED: QnaItem = {
  question: 'I don’t get the results I was looking for. What might be going on?',
  answer:
    'Make sure the photo you use captures the entire item you are looking for. We’re continuously striving to provide you with a better experience. If it’s still not working, you may be looking for an item that is currently not supported by Search By Photo.'
};

export const ABOUT_VS_QNA_NETWORK_ERROR: QnaItem = {
  question: 'Why am I getting a network error?',
  answer:
    'Search By Photo needs an internet connection (over Wi-Fi or data network) to search. If you can’t access the internet right now, you will get a network error message. Check your network settings and try again.'
};
