export const HYDRA_TEST_VARIABLE = 'htv'; // currently not a real test; used for documentation and unit testing
export const HYDRA_ALTERNATIVE_RECO = 'altreco'; // [Alt]ernative [Reco]mmendation source

export const HYDRA_BELOW_ADD_TO_CART_SLOT = 'batc'; // [B]elow [A]dd [T]o [C]art Slot
export const HYDRA_SPLIT_SHIPMENTS = 'ssp'; // [S]plit [S]hi[p]ments
export const HYDRA_BEST_GUESS = 'gbfy'; // [G]uess [B]est [F]or [Y]ou
export const HYDRA_VIP_ENROLLMENT_AT_CHECKOUT = 'vec'; // [V]ip [E]nrollment at [C]heckout
export const HYDRA_VIP_EMAIL_SUBSCRIPTIONS = 'ves'; // [V]ip [E]mail [S]ubscriptions
export const HYDRA_SEARCH_PRODUCT_HOVER = 'sph'; // [S]earch [P]roduct [H]over
export const HYDRA_PRODUCT_GRID_IMAGES = 'pgi'; // [P]roduct [G]rid [I]mages
export const HYDRA_HOMEPAGE_PERSONALIZATION_TEST = 'hppt'; // [H]ome[P]age [P]ersonalization [T]est; pronounced as its spelt.
export const HYDRA_RECO_DRAWER = 'recd'; // [REC]o [D]rawer
export const HYDRA_COLOR_LEGEND_PDP = 'cold'; // [C]ol[O]r [L]egen[D]
export const HYDRA_PHOTO_ANGLES = 'pang'; // [P]hoto [ANG]les
export const HYDRA_SHIPPING_RANGE_DATES = 'srd'; // [S]hipping [R]ange [D]ates
export const HYDRA_CTA_ON_SEARCH = 'cta1'; // [CTA] on search
export const HYDRA_HEY_DUDE_BRAND_PAGE = 'hdbp'; // [H]ey [D]ude [B]rand [P]age
export const HYDRA_HIDE_PAYPAL = 'hpp'; // [H]ide [P]ay[P]al
export const HYDRA_MAFIA_INFERIOR_SUPPRESSION = 'mis'; // [M]afia [I]nferior [S]uppresion - filtering out shipment options
export const HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS = 'pcefi'; // [C]ore [E]xperience [F]unctionality [I]mprovement
export const HYDRA_RETURNS_COSTLY_CUSTOMER = 'rcct'; // [R]eturns [C]ostly [C]ustomer [T]est

// Vrsnl
export const HYDRA_VRSNL_PDP = 'vpdp'; // [V]rsnl [P]roduct [D]etail [P]age
