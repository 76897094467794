import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(customParseFormat); // need this because we receive non-standard date strings from some apis
dayjs.extend(isSameOrBefore);

type DateToParse = string | number | dayjs.Dayjs | Date | null;

export const formatDate = (formatString?: string, date = new Date()) => dayjs(date).format(formatString);
export const parseDate = (dateToParse?: DateToParse, formatString?: dayjs.OptionType) => dayjs(dateToParse, formatString).toDate();
export const addDaysToDateAndFormat = (originalDate: DateToParse, daysToAdd: number, formatString = 'MMM DD, YYYY') =>
  dayjs(originalDate).add(daysToAdd, 'day').format(formatString);
export const isTodayWithinNDaysAfterDate = (originalDate: DateToParse, nDays: number) =>
  dayjs().isSameOrBefore(dayjs(originalDate).add(nDays, 'day'));
export const extractShortMonthDayFromDate = (originalDate?: string) => {
  if (!originalDate) return;
  const parts = originalDate.split(',');
  return parts[0]?.trim();
};
export const parseDateFromMiliseconds = (originalDate: any, noYear: boolean) => {
  const date = new Date(originalDate);

  return noYear
    ? date.toLocaleDateString('en-US', {
        month: 'short',
        day: '2-digit'
      })
    : date.toLocaleDateString('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
      });
};
