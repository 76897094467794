import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';

import { cn } from 'helpers/classnames';
import Link from 'components/hf/HFLink';
import {
  CREATE_ACCOUNT_BTN,
  SIGN_IN_WITH_AMAZON_BTN,
  SIGN_IN_WITH_APPLE_BTN,
  SIGN_IN_WITH_FACEBOOK_BTN,
  SIGN_IN_WITH_GOOGLE_BTN,
  SIGN_IN_WITH_ZAPPOS_BTN
} from 'constants/federatedLoginModalInteractionTypes';
import useMartyContext from 'hooks/useMartyContext';
import { evFederatedLoginModalInteraction } from 'events/global';
import { onFederatedModalPageView } from 'actions/pageView';
import { parseReturnToUrl } from 'helpers/LocationUtils';
import type { AppState } from 'types/app';

import css from 'styles/components/account/federatedLogin.scss';

interface OwnProps {
  copy: any;
  enableProviders?: string;
  returnTo?: string;
  tokenExchangeRedirect?: string;
  isZAW?: boolean;
  zawPartner?: string;
}

interface Content {
  title: string;
  reasons: string[];
  loginButtons: any;
}

interface SignInButton {
  authUrl: string;
  displayIcon: any;
  id: string;
  dataTestId: string;
  interactionType: number;
  text: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps & PropsFromRedux;

export const FederatedLogin = ({
  copy,
  enableProviders = '',
  killswitch,
  pageType,
  sourcePageType,
  onFederatedModalPageView,
  returnTo = '',
  tokenExchangeRedirect = 'false',
  isZAW = false,
  zawPartner = '',
  url: { protocol, host } = {}
}: Props) => {
  const {
    testId,
    marketplace: {
      features: {
        allowTwoDayShippingPrimePerk,
        federatedLogin: { amazonAuthUrl, appleAuthUrl, googleAuthUrl, facebookAuthUrl, zapposAuthUrl, zapposRegisterUrl }
      },
      cookieDomain
    }
  } = useMartyContext();

  const providers = enableProviders.split(','); // Allow query param override of killswitches for mobile apps, see FederatedLoginPage.jsx
  const { enableAppleLogin, enableFacebookLogin, enableGoogleLogin } = killswitch;
  // Ensure marty killswitch logic doesn't affect mobile webview users via the tokenExchangeRedirect parameter
  const showApple = (enableAppleLogin && tokenExchangeRedirect === 'false') || providers.includes('apple');
  const showFacebook = (enableFacebookLogin && tokenExchangeRedirect === 'false') || providers.includes('facebook');
  const showGoogle = (enableGoogleLogin && tokenExchangeRedirect === 'false') || providers.includes('google');
  const validReturnTo = parseReturnToUrl(returnTo, cookieDomain, protocol, host);

  useEffect(() => {
    onFederatedModalPageView(sourcePageType || pageType);
  }, [onFederatedModalPageView, pageType, sourcePageType]);

  const onLoginClick = (e: React.MouseEvent<HTMLLinkElement>) => {
    const { interactionType } = (e.target as HTMLLinkElement).dataset;
    evFederatedLoginModalInteraction(interactionType);
  };

  const SIGN_IN_BUTTON_CONSTANTS = {
    zappos: {
      authUrl: zapposAuthUrl,
      displayIcon: css.zapposIcon,
      id: 'zapposSignIn',
      dataTestId: 'loginWithZappos',
      interactionType: SIGN_IN_WITH_ZAPPOS_BTN,
      text: 'Sign in with Zappos'
    },
    amazon: {
      authUrl: amazonAuthUrl,
      displayIcon: css.amazonIcon,
      id: 'amazonSignIn',
      dataTestId: 'loginWithAmazon',
      interactionType: SIGN_IN_WITH_AMAZON_BTN,
      text: zawPartner === 'amazon' ? 'Sign in with personal Amazon' : 'Sign in with Amazon'
    },
    google: {
      authUrl: googleAuthUrl,
      displayIcon: css.googleIcon,
      id: 'googleSignIn',
      dataTestId: 'loginWithGoogle',
      interactionType: SIGN_IN_WITH_GOOGLE_BTN,
      text: 'Sign in with Google'
    },
    facebook: {
      authUrl: facebookAuthUrl,
      displayIcon: css.facebookIcon,
      id: 'facebookSignIn',
      dataTestId: 'loginWithFacebook',
      interactionType: SIGN_IN_WITH_FACEBOOK_BTN,
      text: 'Sign in with Facebook'
    },
    apple: {
      authUrl: appleAuthUrl,
      displayIcon: css.appleIcon,
      id: 'appleSignIn',
      dataTestId: 'loginWithApple',
      interactionType: SIGN_IN_WITH_APPLE_BTN,
      text: 'Sign in with Apple'
    },
    createAccount: {
      authUrl: zapposRegisterUrl,
      displayIcon: null,
      id: 'zapposRegister',
      dataTestId: 'createAccount',
      interactionType: CREATE_ACCOUNT_BTN,
      text: 'Create your Zappos Account'
    }
  } as const;

  const generateSignInButton = ({ authUrl, displayIcon, id, dataTestId, interactionType, text }: SignInButton) => (
    <Link
      to={`${authUrl}${validReturnTo}&tokenExchangeRedirect=${tokenExchangeRedirect}`}
      className={cn({ [css.zaw]: isZAW, [css.icon]: !!displayIcon }, displayIcon)}
      onClick={onLoginClick}
      id={id}
      data-test-id={testId(dataTestId)}
      data-interaction-type={interactionType}
    >
      {text}
    </Link>
  );

  const SignInButtonDivider = (
    <div className={css.sectionDividerContainer}>
      <p className={css.sectionDividerPrompt}>or</p>
    </div>
  );

  // This method is to easily pull ZAW partner copy and button order
  // The default return case is the default copy and button order for zappos
  const getDisplayContent = (): Content => {
    const {
      title: defaultTitle = 'Sign in or create an account to get this party started!',
      promptReasons: defaultPromptReasons = [
        'Search results tailored to you',
        'Size recommendations on product pages',
        'Personalized brand recommendations',
        '“Heart” items to add to your Favorites and save them for later',
        'Get points for purchases and logging in (Rewards members only)'
      ],
      zawAmazonTitle = 'Create an Account or Sign In',
      zawAmazonPromptReasons = [
        'Please use your personal email address to access Zappos at Work',
        'If this is your first time accessing Zappos at Work, you will be asked to provide additional information after signing in. This information must match Amazon records.',
        '*Please ensure to login with your personal Amazon.com account and not your work account'
      ],
      zawTitle = 'Create an Account or Sign In',
      zawPromptReasons = [
        'Please use your personal email address to access Zappos at Work',
        'If this is your first time accessing Zappos at Work, you will be asked to provide additional information after signing in. This information must match your employers records.'
      ]
    } = copy || {};

    const defaultReasons = [...defaultPromptReasons];
    if (allowTwoDayShippingPrimePerk) {
      defaultReasons.unshift('If you are an Amazon Prime Member, sign in with Amazon to qualify for free upgraded shipping!');
    }
    const { createAccount, zappos, google, facebook, apple, amazon } = SIGN_IN_BUTTON_CONSTANTS;

    if (isZAW) {
      // Uses the same button organization for all instances of ZAW
      const zawLoginButtons = (
        <>
          {generateSignInButton(createAccount)}
          {SignInButtonDivider}
          {generateSignInButton(zappos)}
          {showGoogle && generateSignInButton(google)}
          {showFacebook && generateSignInButton(facebook)}
          {showApple && generateSignInButton(apple)}
          {generateSignInButton(amazon)}
        </>
      );
      switch (zawPartner) {
        case 'amazon':
          return {
            title: zawAmazonTitle,
            reasons: zawAmazonPromptReasons,
            loginButtons: zawLoginButtons
          };
        default:
          return {
            title: zawTitle,
            reasons: zawPromptReasons,
            loginButtons: zawLoginButtons
          };
      }
    } else {
      return {
        title: defaultTitle,
        reasons: defaultReasons,
        loginButtons: (
          <>
            {generateSignInButton(SIGN_IN_BUTTON_CONSTANTS.zappos)}
            {generateSignInButton(SIGN_IN_BUTTON_CONSTANTS.amazon)}
            {showGoogle && generateSignInButton(SIGN_IN_BUTTON_CONSTANTS.google)}
            {showFacebook && generateSignInButton(SIGN_IN_BUTTON_CONSTANTS.facebook)}
            {showApple && generateSignInButton(SIGN_IN_BUTTON_CONSTANTS.apple)}
            {SignInButtonDivider}
            {generateSignInButton(SIGN_IN_BUTTON_CONSTANTS.createAccount)}
          </>
        )
      };
    }
  };

  const { title, reasons, loginButtons }: Content = getDisplayContent();

  return (
    <section className={css.container} data-test-id={testId('fedLoginModal')}>
      <div className={css.body}>
        <div className={css.loginButtons}>
          {/* Ids are added to these links so mobile apps can easily grab/skin them for webview wrappers */}
          {loginButtons}
        </div>
        <div className={css.signInPromptContainer} data-test-id={testId('fedLoginBenefits')}>
          <h3>{title}</h3>
          <ul>
            {reasons.map((reason, index) => (
              <li
                className={cn(css.signInPromptItem, {
                  [css.noListStyle]: isZAW
                })}
                key={index}
              >
                {reason}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <p className={css.terms}>
        By signing in, you agree to Zappos{' '}
        <Link to={'/c/terms-of-use'} data-test-id={testId('termsConditionsLink')}>
          Terms and Conditions
        </Link>{' '}
        and{' '}
        <Link to={'/c/privacy-policy'} data-test-id={testId('privacyPolicyLink')}>
          Privacy Policy
        </Link>
        .
      </p>
    </section>
  );
};

const mapStateToProps = (state: AppState) => {
  const {
    killswitch,
    pageView: { pageType },
    headerFooter: {
      federatedLoginModal: { sourcePageType }
    },
    url
  } = state;

  return {
    killswitch,
    pageType,
    sourcePageType,
    url
  };
};

const mapDispatchToProps = {
  onFederatedModalPageView
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(FederatedLogin);
