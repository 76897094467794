import type { IToggle } from 'unleash-proxy-client';

import { selectHasKratosCheckoutCookie } from './cookies';

import marketplace from 'cfg/marketplace.json';
import type { AppState } from 'types/app';
import {
  FEATURE_BANNER_ADS,
  FEATURE_CHECKOUT_KRATOS,
  FEATURE_ENABLED_VARIANT_NAME_TREATMENT,
  FEATURE_HOMEPAGE_BANNER_ADS,
  FEATURE_MAFIA_AKAMAI,
  FEATURE_MARTY_CLIENT_RENDER,
  FEATURE_MY_ACCOUNT_REDESIGN,
  FEATURE_PDP_ACCORDION_ORDER,
  FEATURE_PDP_PAPER_CUTS
} from 'constants/features';
import type { SignUpDrawerVariant, SignUpDrawerVariantSelector } from 'components/common/SignUpDrawer/SignUpDrawer.types';

export const selectUnleashToggle = (feature: string, state: AppState): IToggle | undefined =>
  (state.unleash?.toggles || []).find((t: IToggle) => t.name === feature);

export const selectABTestEnabled = (testName: string, state: AppState, treatmentValue = '1') =>
  selectUnleashVariant(testName, state) === treatmentValue;

export const selectIsFeatureMafiaAkamai = (state: AppState) => Boolean(selectUnleashToggle(FEATURE_MAFIA_AKAMAI, state)?.enabled);

export const selectIsFeatureMartyClientRender = (state: AppState) => Boolean(selectUnleashToggle(FEATURE_MARTY_CLIENT_RENDER, state)?.enabled);

export const selectIsFeatureBannerAds = (state: AppState) => Boolean(selectUnleashToggle(FEATURE_BANNER_ADS, state)?.enabled);

export const selectIsFeaturePdpPaperCuts = (state: AppState) => Boolean(selectUnleashToggle(FEATURE_PDP_PAPER_CUTS, state)?.enabled);

export const selectIsFeatureHomepageBannerAds = (state: AppState) => Boolean(selectUnleashToggle(FEATURE_HOMEPAGE_BANNER_ADS, state)?.enabled);

export const selectIsPdpAccordionOrder = (state: AppState) =>
  Boolean(selectUnleashToggle(FEATURE_PDP_ACCORDION_ORDER, state)?.variant.name === FEATURE_ENABLED_VARIANT_NAME_TREATMENT);

export const selectIsFeatureKratosCheckout = (state: AppState) =>
  Boolean(
    marketplace.hasKratosCheckout &&
      (selectHasKratosCheckoutCookie(state) ||
        selectUnleashToggle(FEATURE_CHECKOUT_KRATOS, state)?.variant.name === FEATURE_ENABLED_VARIANT_NAME_TREATMENT)
  );

export const selectIsMyAccountRedesign = (state: AppState) =>
  Boolean(marketplace.hasMyAccountRedesign && selectUnleashToggle(FEATURE_MY_ACCOUNT_REDESIGN, state)?.enabled);

export const selectUnleashVariant = (feature: string, state: AppState) => selectUnleashToggle(feature, state)?.variant.payload?.value;

export const selectIsEmailDrawerSignup = (feature: string, unleashList?: { toggles?: IToggle[] }): SignUpDrawerVariantSelector => {
  if (!unleashList?.toggles) {
    return { isEnabled: false, variantVersion: '0' };
  }
  const toggle = unleashList.toggles.find((t: IToggle) => t.name === feature);

  const variantName = toggle?.variant.name;
  const acceptedVariant = variantName === '1' || variantName === '2' || variantName === '3';
  const variantVersion: SignUpDrawerVariant = acceptedVariant ? variantName : '0';

  const isEnabled = Boolean(toggle?.enabled && acceptedVariant);

  return { isEnabled, variantVersion };
};
