import React from 'react';

import { CLOSE_MODAL_MISC } from 'constants/federatedLoginModalInteractionTypes';
import FederatedLogin from 'components/account/FederatedLogin';
import MelodyModal from 'components/common/MelodyModal';
import { evFederatedLoginModalInteraction } from 'events/global';

import css from 'styles/components/account/federatedLoginModal.scss';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  copy: any;
  returnTo: string;
}

export const FederatedLoginModal = ({ isOpen, handleClose, copy = {}, returnTo }: Props) => {
  const onModalClose = (interactionType: number) => {
    // track events for closing modal
    evFederatedLoginModalInteraction(interactionType);
    handleClose();
  };

  return (
    <MelodyModal
      onRequestClose={() => onModalClose(CLOSE_MODAL_MISC)}
      isOpen={isOpen}
      heading="Sign-in"
      headingTestId="fedLoginHeader"
      buttonTestId="close"
      className={css.modalContent}
    >
      <FederatedLogin copy={copy} returnTo={returnTo} />
    </MelodyModal>
  );
};

export default FederatedLoginModal;
