import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Input } from '@mweb/zappos-ui/Input';

import useMartyContext from 'hooks/useMartyContext';
import { checkIfValidEmail } from 'utils/subscriptionsUtils';
import Button from 'components/common/Button';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import UtilityStrokeMailLargeIcon from 'tailwind/components/Icons/UtilityStrokeMailLargeIcon';

export const FooterSignUp = ({ isFooterSubscribeSubmitted, handleSubscribeSubmit, signUp }) => {
  const isSubmitting = useSelector(state => state.headerFooter?.isSubmitting);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [invalidEmailMsg, setInvalidEmailMsg] = useState('Please Enter A Valid Email Address');
  const { testId } = useMartyContext();
  const isValidEmail = email => checkIfValidEmail(email);

  if (!signUp) {
    return null;
  }

  const handleSubmit = event => {
    event.preventDefault();

    const email = event.target.email.value;

    if (!isValidEmail(email)) {
      setIsEmailValid(false);
      setInvalidEmailMsg('Please Enter A Valid Email Address');
      return;
    }

    setIsEmailValid(true);

    if (!isSubmitting) {
      handleSubscribeSubmit(email, 'footer');
    }
  };

  return (
    <div className="bg-primary-minimal px-5 py-10 sm:px-12 sm:py-8" data-slot-id="sign-up-form" data-test-id={testId('newsletterContainer')}>
      <div className="m-auto flex w-full max-w-[1440px] flex-wrap justify-between gap-y-6">
        <div className="max-w-[379px] self-baseline text-base">
          <div className="flex flex-wrap items-center">
            <UtilityStrokeMailLargeIcon size={48} />
            <h3 className="text-2xl text-primary">Get on the List</h3>
          </div>
          Subscribe to Zappos emails for exclusive product launches, early notifications for sales, and a few extra-special surprises.
        </div>
        {!isFooterSubscribeSubmitted && (
          <form
            className="flex w-full flex-wrap gap-4 sm:w-auto"
            method="post"
            onSubmit={handleSubmit}
            data-footer-signup
            data-test-id={testId('footerNewsletterForm')}
            action="/subscription.do"
          >
            <div className="flex w-full flex-wrap items-center gap-4 sm:h-[106px]">
              <Input
                name="email"
                label="Email"
                className="w-full self-start sm:w-[365px] [&_input]:rounded-lg"
                placeholder="Enter Email Address"
                disabled={isSubmitting}
                variant={isEmailValid ? undefined : 'error'}
                helpText={isEmailValid ? undefined : invalidEmailMsg}
                data-test-id={testId('footerNewsletterInput')}
                id="footerSubEmail"
              />
              <Button type="submit" variant="filled" height="small" className="w-full sm:w-fit" data-test-id={testId('footerNewsletterSubmit')}>
                Join the Party
              </Button>
            </div>
          </form>
        )}
        {isFooterSubscribeSubmitted && (
          <div className="flex w-full flex-col flex-wrap items-center justify-center gap-4 text-base sm:w-auto sm:flex-row">
            <div data-test-id={testId('footerNewsletterSuccess')}>
              <div className="text-center font-semibold sm:text-left">Success!</div>
              Thank you for subscribing.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

FooterSignUp.contextTypes = {
  testId: PropTypes.func
};

export default withErrorBoundary('FooterSignUp', FooterSignUp);
