import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import loadable from '@loadable/component';

import usePrevious from 'hooks/usePrevious';
import type { RouteConfig } from 'routes/routesConfig';
import { RouteType } from 'routes/routesConfig';
import SiteAwareMetadata from 'components/SiteAwareMetadata';
import { childPathFactory } from 'helpers/RouteUtils';
import { routeUpdateComplete } from 'actions/pageView';
import ContentSquare from 'containers/ContentSquare/ContentSquare';
import { matchRouteConfig } from 'helpers/route/MatchRouteConfig';
import { Spinner } from 'components/Loader';
import useMyAccountRedesignEnabled from 'hooks/useMyAccountRedesignEnabled';
const LazyAccountContainer = loadable(/* webpackChunkName: "AccountContainer" */ () => import('containers/account/AccountContainer'));

const MOUNT_PATHS = ['/marty', '/'];

const makeAppRoutes = (routesConfig: RouteConfig[], pathPrefix: string, isMyAccRedesign: boolean) => {
  const makePath = childPathFactory(pathPrefix);
  return routesConfig.map(({ path, component, routeType, to, exact }: RouteConfig) => {
    switch (routeType) {
      case RouteType.ROUTE:
        // @ts-ignore - exact still works in react router 5 but ts is complaining about it.
        return <Route key={makePath(path)} exact={exact} path={makePath(path)} component={component} />;
      case RouteType.ACCOUNT_ROUTE:
        if (isMyAccRedesign) {
          return (
            <Route
              key={makePath(path)}
              exact={exact}
              path={makePath(path)}
              render={routeProps => (
                <Suspense fallback={<Spinner />}>
                  <LazyAccountContainer>{React.createElement(component, routeProps)}</LazyAccountContainer>
                </Suspense>
              )}
            ></Route>
          );
        } else {
          // @ts-ignore - exact still works in react router 5 but ts is complaining about it.
          return <Route key={makePath(path)} exact={exact} path={makePath(path)} component={component} />;
        }

      case RouteType.REDIRECT:
        return <Redirect key={makePath(path)} from={makePath(path)} to={makePath(to ?? '')} />;
      default:
        return null;
    }
  });
};

interface RoutesProps {
  routesConfig: RouteConfig[];
}

const Routes = ({ routesConfig }: RoutesProps) => {
  const location = useLocation();
  const isMyAccRedesign = useMyAccountRedesignEnabled();

  const routes = MOUNT_PATHS.map(path => makeAppRoutes(routesConfig, path, isMyAccRedesign));

  const history = useHistory();
  const dispatch = useDispatch();

  const { pathname: currentPathname, search: currentSearch } = location;

  const prevLocation = usePrevious(location);
  const prevHistory = usePrevious(history);

  const prevRouteConfig = matchRouteConfig(routesConfig, prevLocation?.pathname);

  // handle route changes
  useEffect(() => {
    dispatch(routeUpdateComplete());
    const currRouteConfig = matchRouteConfig(routesConfig, location.pathname);

    if (!currRouteConfig) return;

    // @ts-ignore location.action does exists but TS complaining.
    if (location.action === 'POP') {
      return;
    }

    const { params, suppressScrollOnRouteChange } = currRouteConfig;

    const prevRouteProps = {
      location: prevLocation,
      history: prevHistory,
      params: prevRouteConfig?.params
    };
    const currRouteProps = {
      location,
      history,
      params
    };
    const shouldScroll = suppressScrollOnRouteChange ? !suppressScrollOnRouteChange(prevRouteProps, currRouteProps) : true;

    if (shouldScroll) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1);
    }
  }, [currentPathname, currentSearch]);

  return <Switch>{routes}</Switch>;
};

const makeRoutes = (AppNode: new () => React.Component<any, any>, routesConfig: RouteConfig[]) => (
  <SiteAwareMetadata>
    <AppNode>
      <ContentSquare />
      <Routes routesConfig={routesConfig} />
    </AppNode>
  </SiteAwareMetadata>
);

export default makeRoutes;
