import { constructMSAImageUrl } from '.';

import { ABSOLUTE_URL_RE, PAGE_NUMBER_IN_TITLE_RE, SLASH_SEARCH_FILTERS_RE } from 'common/regex';
import { relativizeUrl } from 'helpers/LocationUtils';
import ProductUtils from 'helpers/ProductUtils';
import { retrieveMetaInfo, retrieveTitleTag } from 'helpers/SeoOptimizedDataHelper';
import { buildSeoBrandString, buildSeoProductUrl } from 'helpers/SeoUrlBuilder';
import { makePageLink, organizeSelects } from 'helpers/SearchUtils';
import { createYouTubeContentUrl } from 'helpers/ClientUtils';
import type { Marketplace } from 'types/app';
import type { ProductBundle, ProductStyle } from 'types/cloudCatalog';

const NO_ARCHIVE_META = 'noarchive';
const NO_INDEX_META = 'noindex, nofollow';

type Product = ProductBundle;

type Meta = {
  name?: {
    [key: string]: string;
  };
  property?: {
    [key: string]: string;
  };
  separator?: string;
  title?: string;
  keywords?: string[];
  description?: string;
  landingKeywords?: string[];
};

type DocMeta = {
  title?: string;
  meta?: Meta;
  canonical?: string;
  link?: {
    rel: {
      [key: string]: unknown;
    };
  };
};

type Alt = {
  baseUrl: string;
  attributes: {
    [key: string]: string;
  };
};

type Alternates = {
  alternates: Alt[];
};

type PageInfo = {
  description?: string;
  slotData?: {
    [slotName: string]: {
      componentName: string;
      womenstableimg: unknown;
    };
  };
  brandName: string;
  canonicalUrl?: string;
  pageTitle?: string;
};

const enhanceMetaWithIOSAd = (docMeta: DocMeta, marketplace: Marketplace) => {
  const {
    nativeApps: { ad }
  } = marketplace;
  docMeta.meta = docMeta.meta || { name: {} };
  if (ad) {
    const { iosAppId, iosSmartBannerBaseUrl, showiOSNativeAd } = ad;
    if (showiOSNativeAd && docMeta.canonical) {
      docMeta.meta.name = docMeta.meta.name || {};
      docMeta.meta.name['apple-itunes-app'] = `app-id=${iosAppId}, app-argument=${iosSmartBannerBaseUrl}${relativizeUrl(docMeta.canonical)}`;
    }
  }

  return docMeta;
};

const buildAlternatesForCanonical = (canonical?: string, alts?: Alt[]) => {
  // fix if absolute
  const canonicalPath = relativizeUrl(canonical || '');
  return alts?.map(alt => ({
    href: `${alt.baseUrl}${canonicalPath}`,
    ...alt.attributes
  }));
};

const enhanceMetaWithAlternates = (documentMeta: DocMeta, alts: Alternates) => {
  if (!alts || !alts.alternates) {
    return documentMeta;
  }
  const link = documentMeta.link || { rel: {} };
  link.rel = link.rel || {};
  link.rel.alternate = buildAlternatesForCanonical(documentMeta.canonical, alts.alternates);
  return { ...documentMeta, link };
};

export function buildTitleWithMarketplaceSuffix(defaultMeta: Meta, title?: string) {
  return title ? `${title} ${defaultMeta.separator} ${defaultMeta.title}` : defaultMeta.title;
}

export function buildGenericLandingPageMeta(defaultMeta: Meta, pageInfo: PageInfo) {
  const ret = {
    name: {
      keywords: defaultMeta.keywords?.concat(defaultMeta.landingKeywords || '').join(', '),
      description: pageInfo.description || defaultMeta.description
    },
    property: {} as { [x: string]: unknown }
  };

  const { slotData } = pageInfo;
  if (slotData) {
    for (const slotName in slotData) {
      const slot = slotData[slotName];
      if (slot?.componentName === 'melodySizingGuide') {
        const womensConversionTableImage = slot.womenstableimg;
        if (womensConversionTableImage) {
          ret.property['og:image'] = womensConversionTableImage;
          break;
        }
      }
    }
  }

  return ret;
}

export const buildTaxonomyBrandPageDocMeta = (marketplace: Marketplace, alternates: Alternates, brandId: string, pageInfo: PageInfo) => {
  const { defaultMeta, desktopBaseUrl } = marketplace;
  const meta = {
    title: buildTitleWithMarketplaceSuffix(defaultMeta, pageInfo.brandName),
    canonical: `${desktopBaseUrl}${buildSeoBrandString(pageInfo.brandName, brandId)}`,
    meta: buildGenericLandingPageMeta(defaultMeta, pageInfo)
  };

  return enhanceMetaWithIOSAd(enhanceMetaWithAlternates(meta as DocMeta, alternates), marketplace);
};

//  logic -- use pageInfo.canonicalUrl if it exists and is absolute, if it is relative, use our base URL, otherwise fallback to /c/pageName
const buildLandingPageCanonical = (desktopBaseUrl: string, pageName: string, pageInfo: PageInfo) => {
  if (pageInfo.canonicalUrl) {
    return ABSOLUTE_URL_RE.exec(pageInfo.canonicalUrl) ? pageInfo.canonicalUrl : `${desktopBaseUrl}${pageInfo.canonicalUrl}`;
  }
  return `${desktopBaseUrl}/c/${pageName}`;
};

export const buildLandingPageDocMeta = (marketplace: Marketplace, alternates: Alternates, pageName: string, pageInfo: PageInfo) => {
  const { defaultMeta, desktopBaseUrl } = marketplace;
  const meta = {
    title: buildTitleWithMarketplaceSuffix(defaultMeta, pageInfo.pageTitle),
    canonical: buildLandingPageCanonical(desktopBaseUrl, pageName, pageInfo),
    meta: buildGenericLandingPageMeta(defaultMeta, pageInfo)
  };
  return enhanceMetaWithIOSAd(enhanceMetaWithAlternates(meta as DocMeta, alternates), marketplace);
};

function buildBranchMetaTags({ androidDeeplinkBase }: Marketplace['branchio'], { productId, styles }: Product, colorId: string) {
  const metaTags: Record<string, string> = {
    'branch:deeplink:product': productId,
    'branch:deeplink:$android_deeplink_path': `${androidDeeplinkBase}/product`
  };
  const style = ProductUtils.getStyleByColor(styles, colorId);
  if (style && style.styleId) {
    metaTags['branch:deeplink:style'] = style.styleId;
  }
  return metaTags;
}

function buildOpenGraphMetaTags(
  { brandName, productName, youtubeVideoId }: Product,
  style: ProductStyle,
  pageTitle: string,
  baseSiteTitle: string,
  desktopBaseUrl: string,
  showOpenGraphVideo: boolean,
  gender?: string
) {
  let styleSpecificOpts = {};
  if (style) {
    const ogImageMSAUrl = constructMSAImageUrl(style.imageId, { width: 700 });
    styleSpecificOpts = {
      'og:image': ogImageMSAUrl,
      'og:url': `${desktopBaseUrl}${style.productUrl}`
    };
  }
  const ogMeta: Record<string, string> = {
    'og:title': `${gender}${brandName} ${productName}`,
    'og:site_name': baseSiteTitle,
    'og:type': 'product',
    ...styleSpecificOpts
  };

  if (showOpenGraphVideo && youtubeVideoId) {
    ogMeta['og:video'] = createYouTubeContentUrl(youtubeVideoId);
  }
  return ogMeta;
}

function buildOpenGraphMetaTagsForCollection(
  collectionName: string,
  imageId?: string,
  imageExtension?: string,
  title?: string,
  collectionLink?: string
) {
  let styleSpecificOpts = {};
  if (imageId) {
    const ogImageMSAUrl = imageExtension
      ? constructMSAImageUrl(imageId, {
          width: 1200,
          height: 630,
          extension: imageExtension
        })
      : constructMSAImageUrl(imageId, { width: 1200, height: 630 });
    styleSpecificOpts = {
      'og:image': ogImageMSAUrl,
      'og:image:secure_url': ogImageMSAUrl,
      'og:url': collectionLink
    };
  }

  const ogMeta = {
    'og:title': `${collectionName}`,
    'og:site_name': `${title}`,
    'og:type': 'collection',
    ...styleSpecificOpts
  };
  return ogMeta;
}

function buildOpenGraphMetaTagsForShoppablePost(
  name: string,
  title: string,
  shareLink: string,
  imageId?: string,
  imageExtension?: string,
  mediaType?: string,
  mediaUrl?: string
) {
  let styleSpecificOpts = {};
  if (imageId) {
    const ogImageMSAUrl = imageExtension
      ? constructMSAImageUrl(imageId, {
          width: 1200,
          height: 630,
          extension: imageExtension
        })
      : constructMSAImageUrl(imageId, { width: 1200, height: 630 });
    styleSpecificOpts = {
      ...styleSpecificOpts,
      'og:image': ogImageMSAUrl,
      'og:image:secure_url': ogImageMSAUrl
    };
  }
  if (mediaType === 'video') {
    styleSpecificOpts = {
      ...styleSpecificOpts,
      'og:video': mediaUrl,
      'og:video:type': 'video/mp4'
    };
  }
  styleSpecificOpts = {
    ...styleSpecificOpts,
    'og:image:alt': `Shop ${name}'s Shoppable Post`
  };
  const ogMeta = {
    'og:title': `${name}'s Shoppable Post on Zappos.com`,
    'og:site_name': `${title}`,
    'og:type': 'shoppablePost',
    'og:url': shareLink,
    ...styleSpecificOpts
  };
  return ogMeta;
}

function buildOpenGraphMetaTagsForProfile(name: string, title: string, shareLink: string, imageId?: string, imageExtension?: string) {
  let styleSpecificOpts = {};
  if (imageId) {
    const ogImageMSAUrl = imageExtension
      ? constructMSAImageUrl(imageId, { width: 1200, height: 630, extension: imageExtension })
      : constructMSAImageUrl(imageId, { width: 1200, height: 630 });
    styleSpecificOpts = {
      ...styleSpecificOpts,
      'og:image': ogImageMSAUrl,
      'og:image:secure_url': ogImageMSAUrl
    };
  }
  styleSpecificOpts = { ...styleSpecificOpts, 'og:image:alt': `Shop ${name} page` };
  const ogMeta = {
    'og:title': `${name}'s Zappos Shop Page`,
    'og:site_name': `${title}`,
    'og:type': 'storefront',
    'og:url': shareLink,
    ...styleSpecificOpts
  };
  return ogMeta;
}

function buildOpenGraphMetaTagsForStyleFeedPage(name: string, title: string, shareLink: string, bannerImage: string) {
  let styleSpecificOpts = {};
  if (bannerImage) {
    const ogImageMSAUrl = bannerImage;
    styleSpecificOpts = {
      ...styleSpecificOpts,
      'og:image': ogImageMSAUrl,
      'og:image:secure_url': ogImageMSAUrl
    };
  }
  styleSpecificOpts = { ...styleSpecificOpts, 'og:image:alt': `Style Feed Banner` };
  const ogMeta = {
    'og:title': `${name} Page`,
    'og:site_name': `${title}`,
    'og:type': 'styleFeed',
    'og:url': shareLink,
    ...styleSpecificOpts
  };
  return ogMeta;
}

export function buildCollectionPageDocMeta(
  marketplace: Marketplace,
  url: string,
  alternates: Alternates,
  collectionSubCopy: string,
  collectionId: string,
  collectionName: string,
  imageId: string,
  imageExtension: string,
  isInfluencerCollection: boolean
) {
  const {
    ampBaseUrl,
    desktopBaseUrl,
    defaultMeta: { title }
  } = marketplace;
  collectionName = collectionName.replace(/\u00a0/g, ' ');
  collectionSubCopy = collectionSubCopy.replace(/\u00a0/g, ' ');
  const pageTitle = buildTitleWithMarketplaceSuffix(marketplace.defaultMeta, collectionName);
  const linkLabel = isInfluencerCollection ? 'collection' : 'favorites';
  const collectionLink = collectionId ? `${desktopBaseUrl}/${linkLabel}/${collectionId}` : `${desktopBaseUrl}${url}`;
  const openGraphMeta = buildOpenGraphMetaTagsForCollection(collectionName, imageId, imageExtension, title, collectionLink);
  const keywords = title;
  const meta: DocMeta = {
    title: pageTitle,
    meta: {
      name: {
        keywords
      },
      property: {
        ...openGraphMeta
      }
    },
    canonical: collectionLink
  };

  if (ampBaseUrl && collectionId) {
    meta.link = {
      rel: { amphtml: `${ampBaseUrl}/${linkLabel}/${collectionId}` }
    };
  }

  if (collectionSubCopy.trim() !== '') {
    meta.meta!.name!.description = collectionSubCopy;
    meta.meta!.property!['og:description'] = collectionSubCopy;
  }
  return enhanceMetaWithIOSAd(enhanceMetaWithAlternates(meta, alternates), marketplace);
}

export function buildProfilePageDocMeta(
  marketplace: Marketplace,
  alternates: Alternates,
  name: string,
  shareLink: string,
  imageId: string,
  imageExtension: string
) {
  const {
    defaultMeta: { title }
  } = marketplace;
  name = name?.replace(/\u00a0/g, ' ');
  const pageTitle = `${name}'s Zappos Shop Page`;
  const openGraphMeta = buildOpenGraphMetaTagsForProfile(name, title, shareLink, imageId, imageExtension);
  const keywords = title;
  const meta: DocMeta = {
    title: pageTitle,
    meta: {
      name: {
        keywords
      },
      property: {
        ...openGraphMeta
      }
    }
  };
  const description = `Shop ${name}'s page, Shop recommended products, Fashion, Shoes, Apparel and Accessories, Influencer Content, Influencer recommended, Internet Favorites, Influencer Picks`;
  meta.link = { rel: { amphtml: shareLink } };
  meta.canonical = shareLink;
  meta.meta!.name!.description = description;
  meta.meta!.property!['og:description'] = description;

  return enhanceMetaWithIOSAd(enhanceMetaWithAlternates(meta, alternates), marketplace);
}

export function buildStyleFeedPageDocMeta(marketplace: Marketplace, alternates: Alternates, name: string, shareLink: string, bannerImage: string) {
  const {
    defaultMeta: { title }
  } = marketplace;
  name = name?.replace(/\u00a0/g, ' ');
  const pageTitle = `${name} Page`;
  const openGraphMeta = buildOpenGraphMetaTagsForStyleFeedPage(name, title, shareLink, bannerImage);
  const keywords = title;
  const meta: DocMeta = {
    title: pageTitle,
    meta: {
      name: {
        keywords
      },
      property: {
        ...openGraphMeta
      }
    }
  };
  const description = ``;
  meta.link = { rel: { amphtml: shareLink } };
  meta.canonical = shareLink;
  meta.meta!.name!.description = description;
  meta.meta!.property!['og:description'] = description;

  return enhanceMetaWithIOSAd(enhanceMetaWithAlternates(meta, alternates), marketplace);
}

export function buildProductPageDocMeta(
  marketplace: Marketplace,
  alternates: Alternates,
  compiledPdpMetaDescriptionTemplate: ({
    productName,
    brandName,
    title,
    gender
  }: {
    productName: string;
    brandName: string;
    title: string;
    gender: string;
  }) => string,
  product: Product,
  colorId: string,
  isProductTypeShoesOrClothing: boolean
) {
  const {
    ampBaseUrl,
    branchio,
    desktopBaseUrl,
    defaultMeta: { title, keywords: marketplaceKeywords, separator },
    pdp: { showOpenGraphVideo }
  } = marketplace;
  const { brandName, defaultProductType, genders, productId, productName, styles } = product;

  const gender = ProductUtils.getProductGender(genders);
  const genderName = isProductTypeShoesOrClothing && gender ? `${gender} ` : '';

  const style = ProductUtils.getStyleByColor(styles, colorId);
  const pageTitle = `${genderName}${brandName} ${productName} ${separator} ${title}`;
  const branchMeta = buildBranchMetaTags(branchio, product, colorId);
  const openGraphMeta = buildOpenGraphMetaTags(product, style, pageTitle, title, desktopBaseUrl, showOpenGraphVideo, genderName);
  const keywords = [productName, brandName, defaultProductType].concat(genders).concat(marketplaceKeywords).join(', ');
  const canonical = `${desktopBaseUrl}${buildSeoProductUrl(product, undefined)}`;
  const isTestBrandProductPage = product.brandId === '5502';

  const meta: DocMeta = {
    title: pageTitle,
    canonical,
    // this format is dumb, see react-document-meta
    meta: {
      name: {
        description: compiledPdpMetaDescriptionTemplate({
          productName,
          brandName,
          title,
          gender: genderName
        }),
        keywords,
        robots: NO_ARCHIVE_META,
        ...branchMeta
      },
      property: {
        ...openGraphMeta
      }
    }
  };

  if (ampBaseUrl) {
    meta.link = { rel: { amphtml: `${ampBaseUrl}/product/${productId}` } };
  }

  if (isTestBrandProductPage) {
    meta.meta!.name = { ...meta.meta!.name, robots: NO_INDEX_META };
  }

  return enhanceMetaWithIOSAd(enhanceMetaWithAlternates(meta as DocMeta, alternates), marketplace);
}

export function buildShoppablePostDocMeta(
  marketplace: Marketplace,
  alternates: Alternates,
  name: string,
  shareLink: string,
  postDescription: string,
  imageId: string,
  imageExtension: string,
  mediaType: string,
  mediaUrl: string
) {
  const {
    defaultMeta: { title }
  } = marketplace;
  name = name?.replace(/\u00a0/g, ' ');
  postDescription = postDescription?.replace(/\u00a0/g, ' ') || '';
  const description = `${name}'s Zappos Shoppable Post, Zappos Influencer Program, ${postDescription?.substring(0, 160)}`;
  const pageTitle = `${name}'s Shoppable Post on Zappos.com`;
  const openGraphMeta = buildOpenGraphMetaTagsForShoppablePost(name, title, shareLink, imageId, imageExtension, mediaType, mediaUrl);
  const keywords = title;
  const meta: DocMeta = {
    title: pageTitle,
    meta: {
      name: {
        keywords
      },
      property: {
        ...openGraphMeta
      }
    }
  };
  meta.link = { rel: { amphtml: shareLink } };
  meta.canonical = shareLink;
  meta.meta!.name!.description = description;
  meta.meta!.property!['og:description'] = description;

  return enhanceMetaWithIOSAd(enhanceMetaWithAlternates(meta, alternates), marketplace);
}

// Search meta helpers
const canonical = ({ linkInfo }: { linkInfo: { rel?: string; href?: string }[] }, desktopBaseUrl: string) => {
  const canonicalLink = (linkInfo || []).find(({ rel }) => rel === 'canonical');
  if (canonicalLink) {
    return `${desktopBaseUrl}${canonicalLink.href}`;
  }
  return;
};

export const buildSearchPageDocMeta = (marketplace: Marketplace, alternates: Alternates, searchApiResponse: any) => {
  let title = retrieveTitleTag(searchApiResponse);

  const {
    search: { hasInfinitePagination },
    hasSeoTermPages
  } = marketplace;
  if (hasInfinitePagination) {
    title = title.replace(PAGE_NUMBER_IN_TITLE_RE, '');
  }
  const meta: DocMeta = {
    canonical: canonical(searchApiResponse, marketplace.desktopBaseUrl),
    title,
    meta: { name: retrieveMetaInfo(searchApiResponse) },
    link: { rel: {} }
  };
  // Calypso API  field responses are 1-indexed, the URLs are (wtf) zero indexed.
  // TDo not use previous/next directly from API since it will sometimes include a `-page2` suffix to the SEO test, e.g:
  // `/espadrille-women-shoes-page2/CK_XAcABAeICAgEY.zso?t=espadrille%3F&p=1"`
  // Also, the API returns "previous" rather than "prev" which is what search engines use.
  // In order for the prev/next embedded in pagination to match, we need to "fake" the redux state from the parallel filters reducer
  const filterLikeState = { ...searchApiResponse };
  filterLikeState.si = searchApiResponse.si ? searchApiResponse.si.split(',') : null;
  filterLikeState.selected = organizeSelects(searchApiResponse.filters);
  if (searchApiResponse.currentPage > 1) {
    meta.link!.rel.prev = makePageLink(filterLikeState, null, searchApiResponse.currentPage - 2, hasSeoTermPages);
  }

  if (searchApiResponse.currentPage < searchApiResponse.pageCount) {
    meta.link!.rel.next = makePageLink(filterLikeState, null, searchApiResponse.currentPage, hasSeoTermPages);
  }

  if (searchApiResponse.totalResultCount < 1 || SLASH_SEARCH_FILTERS_RE.test(searchApiResponse.executedSearchUrl)) {
    meta.meta!.name!.robots = NO_INDEX_META;
  } else if (meta.meta!.name!.robots === undefined) {
    meta.meta!.name!.robots = NO_ARCHIVE_META;
  }
  return enhanceMetaWithIOSAd(enhanceMetaWithAlternates(meta, alternates), marketplace);
};

const SIMPLE_PAGE_TYPES = [
  'cart',
  'subscriptions',
  'checkout',
  'orders',
  'orderInformation',
  'payment',
  'address',
  'egc',
  'hmd',
  'confirmation',
  'images',
  'returns',
  'rewards',
  'login',
  'outfit',
  'influencerhub',
  'favorites',
  'account',
  'shipments',
  'my-orders',
  'my-returns',
  'return-item',
  'item-details'
];

export const isSimpleMeta = (pageType: string) => SIMPLE_PAGE_TYPES.includes(pageType);

export const buildSimplePageMeta = (marketplace: Marketplace, alternates: Alternates, pageType: string, relativeLocationString: string) => {
  const meta: DocMeta = {
    canonical: `${marketplace.desktopBaseUrl}${relativeLocationString}`,
    meta: { name: {} }
  };
  switch (pageType) {
    case 'cart':
      meta.title = `My ${marketplace.cart.cartName}`;
      meta.meta!.name!.robots = NO_INDEX_META;
      break;
    case 'subscriptions':
      meta.title = 'Manage Email Subscriptions';
      break;
    case 'checkout':
      meta.title = 'Checkout';
      break;
    case 'orders':
      meta.title = 'Order History';
      break;
    case 'orderInformation':
      meta.title = 'Order Information';
      break;
    case 'payment':
      meta.title = 'Manage My Payment Information';
      break;
    case 'address':
      meta.title = 'Manage My Addresses';
      break;
    case 'egc':
      meta.title = `Send a ${marketplace.shortName} e-Gift Card`;
      break;
    case 'hmd':
      meta.title = "How's My Driving Survey";
      break;
    case 'images':
      meta.meta!.name!.robots = NO_INDEX_META;
      break;
    case 'favorites':
      meta.title = 'Your Lists';
      break;
    case 'account':
    case 'shipments':
      meta.title = 'Your Account';
      break;
    case 'returns':
      meta.title = 'Returns';
      break;
    case 'rewards':
      meta.title = 'Zappos VIP';
      break;
    case 'outfit':
      meta.title = 'View Outfit';
      meta.meta!.name!.robots = 'noindex';
      break;
    case 'influencerhub':
      meta.title = 'Influencer Hub';
      break;
    case 'login':
      meta.title = `${marketplace.shortName} Sign-In`;
      break;
    case 'my-orders':
      meta.title = 'My Orders';
      break;
    case 'my-returns':
      meta.title = 'My Returns';
      break;
    case 'item-details':
      meta.title = 'Item Details';
      break;
    case 'return-item':
      meta.title = 'Return Item';
      break;
  }

  // we can't set this to the default switch case due to some pages not have a title (like `images`)
  if (!meta.title) {
    // such as confirmation page type anything we miss
    meta.title = marketplace.defaultTitle;
  }

  meta.title = buildTitleWithMarketplaceSuffix(marketplace.defaultMeta, meta.title);

  return enhanceMetaWithIOSAd(enhanceMetaWithAlternates(meta, alternates), marketplace);
};

export const buildProductReviewsPageDocMeta = (marketplace: Marketplace, alternates: Alternates, url: string, product: Product) => {
  const { defaultMeta, desktopBaseUrl } = marketplace;
  const { brandName, productName, productId } = product;
  const pageTitle = `${brandName} ${productName} Reviews`;
  // possibly open this up to all product ids https://jira.zappos.net/browse/DK-678
  const canonical = productId === '9697407' ? `${desktopBaseUrl}/product/${productId}` : `${desktopBaseUrl}/product/review/${productId}`;
  const meta = {
    title: buildTitleWithMarketplaceSuffix(defaultMeta, pageTitle),
    canonical
  };
  return enhanceMetaWithIOSAd(enhanceMetaWithAlternates(meta, alternates), marketplace);
};

export const buildWriteReviewPageDocMeta = (marketplace: Marketplace, alternates: Alternates, url: string, product: Product, mediaOnly: boolean) => {
  const { defaultMeta, desktopBaseUrl } = marketplace;
  const baseTitle = mediaOnly ? 'Share Outfit' : 'Add Review';
  let pageTitle = baseTitle;
  if (product) {
    const { brandName, productName } = product;
    pageTitle = `${baseTitle} for ${brandName} ${productName}`;
  }

  const meta = {
    title: buildTitleWithMarketplaceSuffix(defaultMeta, pageTitle),
    canonical: `${desktopBaseUrl}${url}`
  };
  return enhanceMetaWithIOSAd(enhanceMetaWithAlternates(meta, alternates), marketplace);
};

export const updateCanonicalsAndAlternates = (marketplace: Marketplace, alternates: Alternates, url: string, existingDocMeta: DocMeta) => {
  const docMeta = {
    ...existingDocMeta,
    canonical: `${marketplace.desktopBaseUrl}${url}`
  };
  return enhanceMetaWithIOSAd(enhanceMetaWithAlternates(docMeta, alternates), marketplace);
};
