import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import ExecutionEnvironment from 'exenv';

import { setViewportSize } from './actions';

/**
 * This is to be used globally on the app to listen to resize events.
 */
export const useLayoutListener = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!ExecutionEnvironment.canUseDOM) {
      return undefined;
    }
    let timeout: ReturnType<typeof setTimeout> | null = null;
    const handleResize = () => {
      // FUTURE: add layout
      dispatch(
        setViewportSize({
          viewportWidth: window.innerWidth,
          viewportHeight: window.innerHeight,
          screenWidth: window.screen.width,
          screenHeight: window.screen.height
        })
      );
      timeout = null;
    };

    window.addEventListener('resize', () => {
      timeout !== null && clearTimeout(timeout);
      timeout = setTimeout(handleResize, 200);
    });
    if (window.requestAnimationFrame) {
      window.requestAnimationFrame(handleResize);
    } else {
      handleResize();
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      timeout !== null && clearTimeout(timeout);
    };
  }, [dispatch]);
};
