import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import { useInjectRum } from 'hooks/useInjectRum';
import { cn } from 'helpers/classnames';
import Footer from 'hf/Footer';
import ZAWHeader from 'hf/ZAWHeader';
import { setFederatedLoginModalVisibility, setHeaderFooterVisibility } from 'actions/headerfooter';
import { SEARCH_BEST_FOR_YOU_COOKIE } from 'constants/cookies';
import { CHECKOUT_URL_RE, CONFIRMATION_URL_RE } from 'common/regex';
import { setError } from 'actions/errors';
import Header from 'hf/Header';
import MinifiedHeader from 'hf/MinifiedHeader';
import { componentDidCatchFullPageError } from 'common/componentHooks';
import ErrorPage from 'components/error/ErrorPage';
import MicrosoftUetTag from 'containers/MicrosoftUetTag';
import { ensureClass, ensureNoClass } from 'helpers';
import { recordPageView } from 'helpers/rum';
import { updateBestForYou } from 'actions/search';
import { redirectTo, redirectToUrlTest } from 'actions/redirect';
import { setLastPageWasOrderconfirmation } from 'store/ducks/history/actions';
import Profile from 'components/common/Profile';
import { isZAWPage } from 'helpers/zaw';
import { useVariant } from 'containers/UnleashFlagProvider';
import { FEATURE_EMAIL_SIGNUP_DRAWER, FEATURE_LEFT_IN_STOCK } from 'constants/features';
import { selectIsEmailDrawerSignup } from 'selectors/features';
import CartModalOrDrawer from 'components/cart/CartModalOrDrawer';
import SignUpDrawerWrapper from 'components/common/SignUpDrawer/SignUpDrawerWrapper';
import { useLayoutListener } from 'store/ducks/deviceProps/hooks';

const FederatedLoginModal = loadable(() => import('components/account/FederatedLoginModal'));

FederatedLoginModal.displayName = 'FederatedLoginModal';

// order matters
import 'styles/global.scss';
import styles from 'styles/App.scss';

import 'styles/zappos-redesign.scss';

export class App extends Component {
  static beforeFetchDataOnServer(store, location = {}) {
    const { pathname } = location;
    store.dispatch(redirectToUrlTest(pathname));
  }
  static fetchDataOnServer(store) {
    // WARNING: We have seen strange behavior with a/b tests that have triggerAssignments in App.jsx server methods.
    // Consider using the corresponding container file for the page's hydraTest and test thoroughly.
    const {
      cookies,
      router: { location }
    } = store.getState();
    store.dispatch(updateBestForYou(!cookies[SEARCH_BEST_FOR_YOU_COOKIE] || cookies[SEARCH_BEST_FOR_YOU_COOKIE] !== 'active'));
    if (new URLSearchParams(location.search).get('disableHf') === 'true') {
      store.dispatch(setHeaderFooterVisibility(false));
    }
  }

  componentDidMount() {
    const { location: { search, pathname } = {}, setHeaderFooterVisibility } = this.props;
    const params = new URLSearchParams(search);

    recordPageView(pathname);

    if (params.get('disableHf') === 'true') {
      setHeaderFooterVisibility(false);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname: prevPathname },
      wasLastPageOrderConfirmation,
      setLastPageWasOrderconfirmation,
      error: prevError
    } = prevProps;
    const { isFacetsVisible, error, location: { pathname } = {}, redirectToUrlTest } = this.props;

    if (pathname !== prevPathname) {
      recordPageView(pathname);

      const {
        router: { forceRefreshPush }
      } = this.context;
      redirectToUrlTest(pathname, forceRefreshPush);
    }
    const resetLastPageOrderConfirmation =
      wasLastPageOrderConfirmation && !(CONFIRMATION_URL_RE.test(prevPathname) || CHECKOUT_URL_RE.test(prevPathname));

    if (resetLastPageOrderConfirmation) {
      setLastPageWasOrderconfirmation(false);
    }

    // When client routing related to search facets, maintain facet state (ie. keep facets open if needed).
    // Also ensures facet state is maintained while swapping between Wildcard/Search containers.
    if (isFacetsVisible) {
      ensureClass(document.body, styles.activeSidebar);
      ensureNoClass(document.body, 'activeMain');
    } else {
      ensureClass(document.body, 'activeMain');
      ensureNoClass(document.body, styles.activeSidebar);
    }

    // Scroll to top on client-side error
    if (!prevError && error) {
      window.scrollTo(0, 0);
    }
  }

  componentDidCatch(error, info) {
    componentDidCatchFullPageError(this.props.setError, error, info);
  }

  makeChildren() {
    const { error, children, userAgent, rum } = this.props;
    if (error) {
      return <ErrorPage error={error} />;
    }
    return (
      <>
        <AppHooks userAgent={userAgent} rum={rum} />
        {children}
      </>
    );
  }

  handleCloseFederatedLogin = () => {
    const {
      federatedLoginModal: { redirectOnClose },
      setFederatedLoginModalVisibility,
      redirectTo
    } = this.props;
    setFederatedLoginModalVisibility(false);
    !!redirectOnClose && redirectTo(redirectOnClose);
  };

  render() {
    const { content, federatedLoginModal, isHfMinified, isHfVisible, isModalShowing, location: { pathname } = {}, unleash } = this.props;

    const { isEnabled: emailSignUpDrawerEnabled, variantVersion } = selectIsEmailDrawerSignup(FEATURE_EMAIL_SIGNUP_DRAWER, unleash);

    const shouldShowZAWHeader = isZAWPage(pathname);

    let DisplayHeader = Header;
    if (shouldShowZAWHeader) {
      DisplayHeader = ZAWHeader;
    } else if (isHfMinified) {
      DisplayHeader = MinifiedHeader;
    }

    const { isFederatedLoginModalShowing, returnTo } = federatedLoginModal;
    const { Global: { slotData: { federatedLoginModalCopy } = {} } = {} } = content;

    return (
      <div className={styles.columnLayout}>
        <CartModalOrDrawer isOpen={isModalShowing} />
        {emailSignUpDrawerEnabled && <SignUpDrawerWrapper variantVersion={variantVersion} />}
        {isHfVisible === true && (
          <Profile id="Header">
            <DisplayHeader />
          </Profile>
        )}
        <div key="main" className={cn(styles.pageLayout, styles.columnLayoutFill)}>
          <Profile id="Main">
            <main id="main" className={styles.mainContent}>
              <div>{this.makeChildren()}</div>
            </main>
          </Profile>
        </div>
        <MicrosoftUetTag />
        {isHfVisible === true && (
          <div className={styles.columnLayoutLast}>
            <Profile id="Footer">
              <Footer />
            </Profile>
          </div>
        )}
        {isFederatedLoginModalShowing && (
          <FederatedLoginModal
            copy={federatedLoginModalCopy}
            handleClose={this.handleCloseFederatedLogin}
            isOpen={isFederatedLoginModalShowing}
            returnTo={returnTo}
          />
        )}
      </div>
    );
  }
}

const AppHooks = props => {
  const { userAgent, rum } = props;
  useVariant(FEATURE_LEFT_IN_STOCK);
  useInjectRum(rum, userAgent);
  useLayoutListener();
  return null;
};

App.contextTypes = {
  router: PropTypes.object.isRequired
};

App.propTypes = {
  // Injected by React Redux
  error: PropTypes.object,
  // Injected by React Router
  children: PropTypes.node
};

function mapStateToProps(state) {
  const {
    error,
    isFacetsVisible,
    headerFooter: { content, federatedLoginModal, isHfVisible, isHfMinified },
    cart: { isModalShowing },
    router: { location },
    history: { wasLastPageOrderConfirmation },
    url: { userAgent },
    environmentConfig: { rum },
    unleash
  } = state;

  return {
    content,
    error,
    federatedLoginModal,
    isFacetsVisible,
    isHfVisible,
    isHfMinified,
    isModalShowing,
    location,
    wasLastPageOrderConfirmation,
    unleash,
    userAgent,
    rum
  };
}

const connector = connect(mapStateToProps, {
  setError,
  setFederatedLoginModalVisibility,
  setHeaderFooterVisibility,
  setLastPageWasOrderconfirmation,
  redirectTo,
  redirectToUrlTest
});

export default connector(App);
