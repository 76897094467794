import type { ThunkAction } from 'redux-thunk';
import type { AnyAction } from 'redux';
import queryString from 'query-string';

import {
  RECEIVE_SUBSCRIPTIONS_INFO,
  RECEIVE_SUBSCRIPTIONS_SIGNUP_CAPTCHA,
  RECEIVE_SUBSCRIPTIONS_SIGNUP_RESPONSE,
  REQUEST_SUBSCRIPTIONS_INFO,
  SUBSCRIPTION_BRAND_TOGGLED,
  SUBSCRIPTION_LIST_TOGGLED,
  SUBSCRIPTION_STOCK_TOGGLED,
  SUBSCRIPTION_UPCOMING_STYLE_TOGGLED
} from 'constants/reduxActions';
import { authenticationErrorCatchHandlerFactory } from 'actions/errors';
import {
  getSubscriptionMyAccount,
  getSubscriptionVillageIdiot,
  requestSubscriptionSignupCaptcha,
  submitSubscriptionSignup,
  subscribeToListZSub,
  unsubscribeFromAll,
  updateSubscriptionMyAccount,
  updateSubscriptionMyAccountOptOut,
  updateSubscriptionMyAccountUnsubscribeAll,
  updateSubscriptionVillageIdiot
} from 'apis/mafia';
import { SHAMELESS_PLUG_LIST_ID } from 'constants/appConstants';
import type { ErrorEventInfo } from 'helpers/ErrorUtils';
import { fetchApiNotAuthenticatedMiddleware, fetchErrorMiddleware, fetchErrorMiddlewareMaybeJson } from 'middleware/fetchErrorMiddleware';
import { setSessionCookies } from 'actions/session';
import { prependAppRoot } from 'history/AppRootUtils';
import { processHeadersMiddleware } from 'middleware/processHeadersMiddlewareFactory';
import { replaceAuthTokenSpace } from 'helpers/MyAccountUtils';
import { trackError } from 'helpers/ErrorUtils';
import marketplace from 'cfg/marketplace.json';
import { selectMafiaConfig } from 'selectors/environment';
import type { AppState } from 'types/app';
import { getSubsiteId } from 'helpers/ClientUtils';
import { setFooterSubscribeError, setFooterSubscribeSubmitted, setHFFootSubscribing } from 'actions/headerfooter';
import type {
  EmailList,
  RecieveSubscriptionsSignupResponseAction,
  SignUpResponse,
  SubscriptionResponse,
  Subscriptions,
  ZSubscriptionFullArgs
} from 'types/subscriptions';

export const REQUEST_TYPE_MYACCOUNT_TOKEN = 'token';
export const REQUEST_TYPE_MYACCOUNT_AUTH = 'auth';
export const REQUEST_TYPE_VILLAGEIDIOT = 'villageIdiot';
export const REQUEST_TYPE_SIGNUP = 'signup';
export const REQUEST_TYPE_SUCCESS_MESSAGE = 'success';

const { siteId } = marketplace;

export function toggleSubscriptionList(emailListName: string) {
  return {
    type: SUBSCRIPTION_LIST_TOGGLED,
    selectedEmailListName: emailListName
  };
}

export function toggleSubscriptionBrand(id: string) {
  return {
    type: SUBSCRIPTION_BRAND_TOGGLED,
    selectedBrandId: id
  };
}

export function toggleSubscriptionStock(asin: string) {
  return {
    type: SUBSCRIPTION_STOCK_TOGGLED,
    selectedAsin: asin
  };
}

export function toggleSubscriptionUpcomingStyle(styleId: string) {
  return {
    type: SUBSCRIPTION_UPCOMING_STYLE_TOGGLED,
    selectedUpcomingStyleId: Number(styleId)
  };
}

export function requestSubscriptionsInfo() {
  return {
    type: REQUEST_SUBSCRIPTIONS_INFO
  };
}

export function recieveSubscriptionsSignupCaptcha(captcha: string) {
  return {
    type: RECEIVE_SUBSCRIPTIONS_SIGNUP_CAPTCHA,
    captcha
  };
}

export function recieveSubscriptionsSignupResponse(successfulSignup: boolean, errors: string[] = []): RecieveSubscriptionsSignupResponseAction {
  return {
    type: RECEIVE_SUBSCRIPTIONS_SIGNUP_RESPONSE,
    successfulSignup,
    errors
  };
}

export function receiveSubscriptionsInfo(response: SubscriptionResponse, requestType: string) {
  if (requestType === REQUEST_TYPE_VILLAGEIDIOT) {
    const subscriptions = response;
    return {
      type: RECEIVE_SUBSCRIPTIONS_INFO,
      subscriptions
    };
  } else {
    if (response.optout) {
      return {
        type: RECEIVE_SUBSCRIPTIONS_INFO,
        optout: true
      };
    }
    return {
      type: RECEIVE_SUBSCRIPTIONS_INFO,
      subscriptions: {
        emailLists: response.emailLists
      }
    };
  }
}

export function translateSubscriptionDataForMyAccount(subscriptions: Subscriptions) {
  const changes: EmailList[] = [];
  Object.values(subscriptions).forEach(emailLists => {
    emailLists.forEach(({ emailListId, emailListName, subscribed }: { emailListId: string; emailListName: string; subscribed: boolean }) => {
      changes.push({
        emailListId,
        emailListName,
        subscribed
      });
    });
  });
  return {
    brandSubscriptions: [],
    emailSubscriptions: changes,
    stockSubscriptions: []
  };
}

function generateZSubscriptionArgs(state: AppState): ZSubscriptionFullArgs {
  const {
    cookies,
    router: {
      location: { search }
    }
  } = state;
  const mafiaConfig = selectMafiaConfig(state);
  const { auth_token: emailAuthToken } = queryString.parse(search) || {};
  return [mafiaConfig, cookies, { auth_token: replaceAuthTokenSpace(emailAuthToken) }];
}

export function generateFetchSubscriptionsApiCall(requestType: string, state: AppState, requestSpecs?: Record<string, any> | undefined) {
  const { cookies } = state;
  const mafiaConfig = selectMafiaConfig(state);

  switch (requestType) {
    case REQUEST_TYPE_VILLAGEIDIOT:
      return getSubscriptionVillageIdiot(...generateZSubscriptionArgs(state));
    case REQUEST_TYPE_MYACCOUNT_AUTH:
      return getSubscriptionMyAccount(mafiaConfig, cookies);
    case REQUEST_TYPE_MYACCOUNT_TOKEN:
      return getSubscriptionMyAccount(mafiaConfig, {}, requestSpecs);
    default:
      return;
  }
}

export function generateUpdateSubscriptionsApiCall(
  requestType: string,
  { subscriptionsDelta, optout }: { subscriptionsDelta: Subscriptions; optout: boolean },
  unsubscribeAll: boolean,
  requestSpecs: Record<string, any>,
  state: AppState
) {
  const { cookies } = state;
  const mafiaConfig = selectMafiaConfig(state);
  if (optout) {
    return unsubscribeAll
      ? updateSubscriptionMyAccountUnsubscribeAll(mafiaConfig, cookies, requestSpecs)
      : updateSubscriptionMyAccountOptOut(mafiaConfig, cookies, requestSpecs.token);
  }
  switch (requestType) {
    case REQUEST_TYPE_VILLAGEIDIOT:
      const [subscriptionArgs, cookieArgs, queryParamAuth] = generateZSubscriptionArgs(state);
      return unsubscribeAll
        ? unsubscribeFromAll(subscriptionArgs, cookieArgs, queryParamAuth)
        : updateSubscriptionVillageIdiot(subscriptionsDelta, subscriptionArgs, cookieArgs, queryParamAuth);
    case REQUEST_TYPE_SIGNUP:
      // TODO handle logged in and email-based signups
      return;
    case REQUEST_TYPE_MYACCOUNT_AUTH:
      return unsubscribeAll
        ? unsubscribeFromAll(mafiaConfig, cookies)
        : updateSubscriptionMyAccount(translateSubscriptionDataForMyAccount(subscriptionsDelta), mafiaConfig, cookies);
    case REQUEST_TYPE_MYACCOUNT_TOKEN:
      return unsubscribeAll
        ? updateSubscriptionMyAccountUnsubscribeAll(mafiaConfig, cookies, requestSpecs)
        : updateSubscriptionMyAccount(translateSubscriptionDataForMyAccount(subscriptionsDelta), mafiaConfig, {}, null);
  }
}

export function fetchSubscriptionsSignupCaptcha(useCaptcha = true): ThunkAction<void, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const {
      router: { location }
    } = state;
    const mafiaConfig = selectMafiaConfig(state);

    const req = useCaptcha
      ? requestSubscriptionSignupCaptcha(mafiaConfig)
          .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
          .then(fetchApiNotAuthenticatedMiddleware)
          .then(fetchErrorMiddleware)
      : Promise.resolve(null);
    return req
      .then((resp: string) => dispatch(recieveSubscriptionsSignupCaptcha(resp)))
      .catch(authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot('/subscriptions', location)));
  };
}

export function updateSubscriptionResponse(response: SubscriptionResponse, requestType: string) {
  response.emailLists = response.emailSubscriptions;
  delete response.emailSubscriptions;
  if (requestType === REQUEST_TYPE_MYACCOUNT_TOKEN) {
    response.optout = response.isOptOut;
    delete response.isOptOut;
  }
  return response;
}

export function fetchSubscriptionsInfo({
  requestSpecs,
  requestType
}: {
  requestSpecs?: Record<string, any> | undefined;
  requestType: string;
}): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    dispatch(requestSubscriptionsInfo());
    const {
      router: { location }
    } = getState();
    return generateFetchSubscriptionsApiCall(requestType, getState(), requestSpecs)
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware)
      .then((response: SubscriptionResponse) => dispatch(receiveSubscriptionsInfo(updateSubscriptionResponse(response, requestType), requestType)))
      .catch(authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot('/subscriptions', location)));
  };
}

export function updateSubscriptions(
  subscriptionInfo: { subscriptionsDelta: Subscriptions; optout: boolean },
  unsubscribeAll: boolean,
  { requestSpecs, requestType }: { requestSpecs: Record<string, any>; requestType: string }
): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    dispatch(requestSubscriptionsInfo());
    const {
      router: { location }
    } = getState();
    return generateUpdateSubscriptionsApiCall(requestType, subscriptionInfo, unsubscribeAll, requestSpecs, getState())
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddlewareMaybeJson)
      .then(() => {
        dispatch(recieveSubscriptionsSignupResponse(true));
        dispatch(fetchSubscriptionsInfo({ requestSpecs, requestType }));
      })
      .catch(authenticationErrorCatchHandlerFactory(dispatch, prependAppRoot('/subscriptions', location)));
  };
}

export function signupForMailingList(recipientEmail: string, answer: string, token: string): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    dispatch(requestSubscriptionsInfo());

    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return submitSubscriptionSignup(mafiaConfig, cookies, {
      recipientEmail,
      answer,
      token
    })
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchErrorMiddlewareMaybeJson)
      .then(() => dispatch(recieveSubscriptionsSignupResponse(true)))
      .catch((e: ErrorEventInfo) => {
        trackError('NON-FATAL', 'Could not make Captcha protected API Call.', e);
        dispatch(fetchSubscriptionsSignupCaptcha());
        // TODO create captcha error middleware when captcha functionality is consolidated.
        dispatch(recieveSubscriptionsSignupResponse(false, e.status === 401 ? ['captcha'] : ['email']));
      });
  };
}

export function handleSubscribeSubmit(
  emailAddress: string | undefined,
  location: 'footer' | 'emailSignupDrawer'
): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;

    const subsiteId = getSubsiteId(marketplace);
    const mafiaConfig = selectMafiaConfig(state);

    if (location === 'footer') {
      dispatch(setHFFootSubscribing());
    }

    return subscribeToListZSub(
      mafiaConfig,
      {
        siteId,
        subsiteId,
        listIds: [SHAMELESS_PLUG_LIST_ID],
        emailAddress
      },
      cookies
    )
      .then(fetchErrorMiddlewareMaybeJson)
      .then((response: SignUpResponse) => {
        if (location === 'footer') {
          dispatch(setFooterSubscribeSubmitted(true));
        }
        return response;
      })
      .catch((err: ErrorEventInfo) => {
        if (location === 'footer') {
          dispatch(setFooterSubscribeError(true));
        }
        trackError('ERROR', 'Footer newsletter subscribe failure', err);
      });
  };
}
