// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeCloseMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path d="M8 7.33337L24.6667 24M8 24L24.6667 7.33337" stroke="currentcolor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);

export default UtilityStrokeCloseMediumIcon;
