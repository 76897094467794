import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import usePrevious from 'hooks/usePrevious';
import { fetchProductRelations } from 'apis/calypso';
import { fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import { convertProductLandingToProductWithRelations, flattenProductRelations } from 'helpers/RecoUtils';
import type { ProductWithRelationsFromCalypso } from 'types/calypso';
import { trackError } from 'helpers/ErrorUtils';
import type { AppState } from 'types/app';
import type { ProductLandingWithRelations } from 'types/landing';

const arrayEquals = (a: string[], b: string[]) =>
  Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val?.toLowerCase() === (b[index] || '').toLowerCase());

const useFetchProductRelation = (styleIds: string[], hasRelatedStyles: boolean = false, products: ProductLandingWithRelations[] = []) => {
  const prevStyleIds = usePrevious(styleIds) || [];
  const calypso = useSelector((state: AppState) => state.environmentConfig.api.calypso);

  const [productRelations, setProductRelations] = useState<{ [s: string]: ProductWithRelationsFromCalypso }>();

  // Since we should not call hooks conditionally, below is the easiest way I found to skip the Calypso call if ZCS has already returned swatch information
  const getRelations = useCallback(async () => {
    const response: { results: ProductWithRelationsFromCalypso[] } = await fetchErrorMiddleware(
      (await fetchProductRelations(calypso, styleIds)) as Response
    );
    setProductRelations(flattenProductRelations(response.results));
  }, [calypso, styleIds]);

  useEffect(() => {
    if (!hasRelatedStyles && !arrayEquals(styleIds, prevStyleIds)) {
      getRelations().catch(e => {
        trackError('NON-FATAL', 'Could not get Product details', e);
      });
    } else if (hasRelatedStyles && !arrayEquals(styleIds, prevStyleIds)) {
      // If related styles field is present, convert products to type ProductWithRelationsFromCalypso for compatibility
      const convertedProducts: ProductWithRelationsFromCalypso[] = products.map(product => convertProductLandingToProductWithRelations(product));
      setProductRelations(flattenProductRelations(convertedProducts));
    }
  }, [styleIds, prevStyleIds, hasRelatedStyles, products, getRelations]);

  return [productRelations];
};

export default useFetchProductRelation;
