import { cva } from 'class-variance-authority';

export const contentClasses = cva(
  'fixed bottom-0 md:left-0 z-[999] flex w-full flex-col ease-out focus:z-[999] data-[state=closed]:duration-300 data-[state=open]:duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out md:w-[420px]',
  {
    variants: {
      position: {
        bottom: 'data-[state=closed]:slide-out-to-bottom-[100%] data-[state=open]:slide-in-from-bottom-[100%]',
        left: 'data-[state=closed]:slide-out-to-left-[100%] data-[state=open]:slide-in-from-left-[100%]',
        fromMinimized:
          'data-[state=closed]:slide-out-to-left-[100%] data-[state=open]:slide-in-from-left-[100%] data-[state=closed]:slide-out-to-bottom-[100%] data-[state=closed]:zoom-out-100 data-[state=open]:zoom-in-100 data-[state=open]:slide-in-from-bottom-[100%]'
      }
    }
  }
);

// z-index is set to 20 to not intefere with AddToBagDrawer & Mobile Nav
export const minimizedButtonClasses = cva(
  'fixed bottom-0 left-0 z-[20] h-[48px] w-[144px] data-[state-visible=false]:duration-300 data-[state-visible=true]:duration-300 data-[state-visible=true]:animate-in data-[state-visible=false]:animate-out ease-out fill-mode-forwards',
  {
    variants: {
      experience: {
        mobile: 'data-[state-visible=false]:slide-out-to-bottom-[100%] data-[state-visible=true]:slide-in-from-bottom-[100%]',
        minimized: 'data-[state-visible=false]:slide-out-to-bottom-[100%] data-[state-visible=true]:slide-in-from-bottom-[100%]'
      }
    }
  }
);
