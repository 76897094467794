import { EVENT_ON_DEMAND, EVENT_SIZING_IMPRESSION, PRODUCT_DESCRIPTION_TOGGLE, RECEIVE_PDP_STORY_SYMPHONY_COMPONENTS } from 'constants/reduxActions';
import {
  BADGE_CATEGORY_CLICK,
  OUT_OF_STOCK_MODAL_VIEW,
  PRODUCT_COUNTRY_UNIT_EVENT,
  PRODUCT_EMPTY_SIZE_OPTIONS_EVENT,
  PRODUCT_FEATURED_ZOOM_EVENT,
  PRODUCT_GENDER_EVENT,
  PRODUCT_IMAGE_IMPRESSION,
  PRODUCT_IMAGE_SWIPE_EVENT,
  PRODUCT_IMAGE_THUMBNAIL_EVENT,
  PRODUCT_LOWEST_RECENT_PRICE,
  PRODUCT_NO_STYLE_SIZES,
  PRODUCT_PAGE_VIEW,
  PRODUCT_SHARE_ICON_TOOLTIP_EVENT,
  PRODUCT_SOCIAL_ICON_EVENT,
  PRODUCT_VIEW_SIZE_CHART,
  PRODUCT_ZOOM_IN_EVENT,
  PRODUCT_ZOOM_OUT_EVENT,
  RECO_DRAWER_BUTTON_CLICK,
  SIZING_IMPRESSION
} from 'constants/amethyst';
import { middlewareTrack } from 'apis/amethyst';
import { toFloatInt } from 'helpers/NumberFormats';
import ProductUtils from 'helpers/ProductUtils';
import { getAmethystPageType, trackEvent } from 'helpers/analytics';
import { store } from 'entrypoints/bootstrapOnClient';
import { getBadgeId } from 'helpers/BadgeUtils';

const PRODUCT_PAGEVIEW = 'PRODUCT_PAGEVIEW';

export const pvProduct = ({ productId, styleId, price, isQuickView, badges, hasReviews, hasVideo, hasBrandSizeChart, accordions }) => ({
  [PRODUCT_PAGE_VIEW]: {
    productIdentifiers: {
      productId: toFloatInt(productId),
      styleId: toFloatInt(styleId),
      supplementalData: {
        badgeId: getBadgeId(badges)
      }
    },
    viewedPrice: toFloatInt(price),
    isAmp: false,
    isQuickView,
    accordions,
    hasReviews,
    hasVideo,
    hasBrandSizeChart
  }
});

const productDimensions = ['SIZE_DIMENSION', 'WIDTH_DIMENSION', 'INSEAM_DIMENSION', 'COLOR_DIMENSION'];
const getCleanDimension = dimension => productDimensions.find(d => d.includes(dimension?.toUpperCase())) || 'UNKNOWN_PRODUCT_DIMENSION';
export const evProductDimensionSelected = ({
  asin,
  dimensionDirty,
  dimension,
  dimensionId,
  dimensionLabel,
  productDimensionSelectionSource = 'UNKNOWN_PRODUCT_DIMENSION_SELECTION_SOURCE',
  stock,
  sourcePage,
  isNew
}) => {
  if (!dimension) {
    dimension = dimensionDirty ? getCleanDimension(dimensionDirty) : 'UNKNOWN_PRODUCT_DIMENSION';
  }

  const eventData = {
    asin,
    dimension,
    dimensionId: toFloatInt(dimensionId),
    dimensionLabel,
    productDimensionSelectionSource,
    sourcePage,
    isNew
  };
  if (stock?.onHand < 10) {
    eventData.stockAlert = true;
    eventData.itemsLeft = toFloatInt(stock.onHand);
  }
  return {
    productDimensionSelected: eventData
  };
};

const ameOnDemand = (appState, { event, value }) => {
  if (event) {
    const eventTypeMap = {
      start: 'ON_DEMAND_START',
      exit: 'ON_DEMAND_EXIT',
      next: 'ON_DEMAND_NEXT',
      edit: 'ON_DEMAND_EDIT',
      apply: 'ON_DEMAND_APPLY_SIZE',
      save: 'ON_DEMAND_SAVE',
      brand: 'BRAND_INPUT',
      category: 'PRODUCT_CATEGORY_INPUT',
      size: 'SIZE_INPUT'
    };
    const payload = {
      onDemandInputType: eventTypeMap[event] || 'UNKNOWN_ON_DEMAND_INPUT'
    };

    if (value) {
      payload.inputValue = value;
    }

    middlewareTrack({
      onDemandSizing: payload
    });
  }
};

const ameSizingImpression = ({ product }, { event, sizeObj }) => {
  const { detail, colorId, selectedSizing } = product;
  if (event) {
    const payload = {
      sizingImpressionType: event
    };
    if (sizeObj?.value) {
      const { id, value } = sizeObj;
      const stock = ProductUtils.getStockBySize(detail.sizing.stockData, colorId, selectedSizing);
      payload.sizeId = toFloatInt(id);
      payload.sizeLabel = value;

      if (stock?.onHand < 10) {
        payload.stockAlert = true;
        payload.itemsLeft = toFloatInt(stock.onHand);
      }
    }
    middlewareTrack({
      [SIZING_IMPRESSION]: payload
    });
  }
};

const pdpStoryImpressions = appState => {
  const {
    product: {
      symphonyStory: { stories, productId, slotData }
    }
  } = appState;
  if (stories?.length) {
    trackEvent('TE_PDP_STORIES_IMPRESSION', `productId:${productId}:componentCount:${stories.length}`);
  }
  Object.keys(slotData).forEach(slotName => {
    if (slotName.includes('buybox')) {
      trackEvent('TE_PDP_BUYBOX_CONTENT_IMPRESSION', slotName);
    }
  });
};

export const sizeBiasImpression = ({ text, productId, sizeBiases }) => {
  trackEvent('TE_PDP_BUYBOX_SIZE_BIAS_IMPRESSION', `productId:${productId}:${text}`);
  return {
    sizeBiasImpression: {
      sourcePage: 'PRODUCT_PAGE',
      productIdentifiers: { productId },
      text,
      sizeBiases
    }
  };
};

const evProductDescriptionToggle = (_, { payload }) => {
  // Naming convention on this dataset was legacy, didn't want to change if there was other tracking being used elsewhere
  switch (payload) {
    case 'Show-More': {
      return middlewareTrack({
        showMoreInformationClick: {}
      });
    }
    case 'Show-less': {
      return middlewareTrack({
        showLessInformationClick: {}
      });
    }
    default: {
      return;
    }
  }
};

export const evProductCalloutImpression = value => {
  if (!value) return;

  return {
    productCalloutImpression: {
      productCalloutAttribute: value
    }
  };
};

export const evGender = ({ dimension, gender }) => ({
  [PRODUCT_GENDER_EVENT]: {
    dimension,
    dimension_label: gender
  }
});

export const evCountryUnit = ({ selectedSizing }) => ({
  [PRODUCT_COUNTRY_UNIT_EVENT]: {
    selected_sizing: selectedSizing
  }
});

export const evEmptySizeOptions = ({ selectedSizing, product }) => {
  const { colorId, detail } = product || {};
  const {
    productId,
    sizing: { stockData }
  } = detail || {};
  const { styleId } = ProductUtils.getStyleByColor(detail.styles, colorId) || {};
  const { id } = ProductUtils.getStockBySize(stockData, colorId, product.selectedSizing) || {};
  return {
    [PRODUCT_EMPTY_SIZE_OPTIONS_EVENT]: {
      selectedSizing,
      productIdentifiers: {
        colorId,
        productId,
        styleId,
        stockId: id
      }
    }
  };
};

export const evSocialIcon = ({ linkName }) => ({
  [PRODUCT_SOCIAL_ICON_EVENT]: {
    link: linkName
  }
});

export const evShareIconTooltip = ({ productId, styleId }) => ({
  [PRODUCT_SHARE_ICON_TOOLTIP_EVENT]: {
    productIdentifiers: {
      productId,
      styleId
    }
  }
});

export const evImageSwipe = ({ asset }) => {
  const imageAngle = asset?.angleType;

  if (!imageAngle) {
    return;
  }

  return {
    [PRODUCT_IMAGE_SWIPE_EVENT]: {
      imageAngle
    }
  };
};

export const evImageThumbnail = ({ src, alt }) => ({
  [PRODUCT_IMAGE_THUMBNAIL_EVENT]: {
    src,
    alt
  }
});

export const evFeaturedZoom = ({ src, alt }) => ({
  [PRODUCT_FEATURED_ZOOM_EVENT]: {
    src,
    alt
  }
});

export const evViewSizeChart = () => ({
  [PRODUCT_VIEW_SIZE_CHART]: {}
});

export const evZoomIn = () => ({
  [PRODUCT_ZOOM_IN_EVENT]: {}
});

export const evZoomOut = () => ({
  [PRODUCT_ZOOM_OUT_EVENT]: {}
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/OutOfStockModalView.proto
export const pvOutOfStockModalView = ({ colorId, dimensionId, dimensionLabel, productId, styleId, sourcePage }) => ({
  [OUT_OF_STOCK_MODAL_VIEW]: {
    productIdentifiers: {
      colorId,
      productId,
      styleId
    },
    productDimensionSelected: {
      dimensionId,
      dimensionLabel
    },
    sourcePage
  }
});

export const evRecoDrawerButtonClick = () => ({
  [RECO_DRAWER_BUTTON_CLICK]: {}
});

export const evProductNoStyleSizes = ({ productId, styleId }) => ({
  [PRODUCT_NO_STYLE_SIZES]: {
    productIdentifiers: {
      productId,
      styleId
    }
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/BadgeCategoryClick.proto
export const evBadgeCategoryClick = ({ badges, styleId, amethystRecoType }) => ({
  [BADGE_CATEGORY_CLICK]: {
    badgeId: getBadgeId(badges),
    categoryName: badges?.[0]?.zc,
    productIdentifiers: styleId,
    sourcePage: getAmethystPageType(store.getState().pageView.pageType),
    widgetType: amethystRecoType
  }
});

export const evProductImageImpression = ({ productId, styleId, imageAngles }) => ({
  [PRODUCT_IMAGE_IMPRESSION]: {
    productIdentifiers: { productId, styleId },
    imageAngle: imageAngles
  }
});

export const evCantFindYourSizeClick = () => ({ cantFindYourSizeClick: {} });
export const evProductLowestRecentPrice = ({ productId, styleId }) => ({
  [PRODUCT_LOWEST_RECENT_PRICE]: {
    productIdentifiers: { productId, styleId }
  }
});

export default {
  pageEvent: PRODUCT_PAGEVIEW,
  events: {
    [EVENT_ON_DEMAND]: [ameOnDemand],
    [EVENT_SIZING_IMPRESSION]: [ameSizingImpression],
    [RECEIVE_PDP_STORY_SYMPHONY_COMPONENTS]: [pdpStoryImpressions],
    [PRODUCT_DESCRIPTION_TOGGLE]: [evProductDescriptionToggle],
    [OUT_OF_STOCK_MODAL_VIEW]: [pvOutOfStockModalView]
  }
};
