import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';

import SignUpDrawer from './SignUpDrawer';
import type { MinimizedButtonExperience, SignUpDrawerPosition, SignUpDrawerVariant } from './SignUpDrawer.types';

import type { AppDispatch } from 'entrypoints/bootstrapOnClient';
import useMartyContext from 'hooks/useMartyContext';
import { handleSubscribeSubmit } from 'actions/account/subscriptions';
import { selectIsMobile } from 'selectors/headerFooter';
import { saveToLocalStorage } from 'helpers/localStorageUtilities';
import { EMAIL_SIGNUP_DRAWER_LOCAL_STORAGE_KEY } from 'constants/appConstants';
import useFetchSubscriptions from 'hooks/useFetchSubscriptions';
import useEmailSignupDrawerState from 'hooks/useEmailSignupDrawerState';
import useFetchAccountInfo from 'hooks/useFetchAccountInfo';
import { handleSubscribeSubmitted } from 'events/global';
import { selectIsCustomer } from 'selectors/cookies';
import { selectCustomerEmailAddress } from 'selectors/account';
import { selectSubscriptionInfo } from 'selectors/subscriptions';
import {
  EMAIL_ADDRESS,
  EMAIL_STAR,
  EMAIL_VALIDATION_DELAY,
  FIRST_TIME_SUBSCRIBER_DELAY,
  PLEASE_ENTER_VALID_EMAIL,
  SIGN_UP_WITH_YOUR_EMAIL
} from 'constants/emailSignupDrawer';
import { checkIfValidEmail } from 'utils/subscriptionsUtils';

const SignUpDrawerWrapper = ({ variantVersion }: { variantVersion: SignUpDrawerVariant }) => {
  const dispatch: AppDispatch = useDispatch();
  const isMobile = useSelector(selectIsMobile);
  const subscriptionInfo = useSelector(selectSubscriptionInfo);
  const customerEmailAddress = useSelector(selectCustomerEmailAddress);
  const isCustomer = useSelector(selectIsCustomer);

  useFetchSubscriptions(isCustomer);
  useFetchAccountInfo();

  const isFetchedCustomer = isCustomer && customerEmailAddress !== undefined;

  const {
    subscriptions: { emailLists }
  } = subscriptionInfo;

  const { marketplace, testId } = useMartyContext();
  const { features: { showEmailSignupDrawer } = {} } = marketplace;

  const {
    isAccepted,
    setIsAccepted,
    isEmailValid,
    setIsEmailValid,
    invalidEmailMsg,
    setInvalidEmailMsg,
    emailAddress,
    setEmailAddress,
    isDrawerVisible,
    setIsDrawerVisible,
    isFocused,
    setIsFocused,
    isMinimizedShown,
    setIsMinimizedShown,
    isSubmitting,
    setIsSubmitting,
    isSubmitted,
    setIsSubmitted,
    isOpen,
    setIsOpen,
    isFirstTimeSubscriber,
    setIsFirstTimeSubscriber,
    shouldRenderMinimized,
    setShouldRenderMinimized,
    isDismissed,
    setIsDismissed
  } = useEmailSignupDrawerState(isCustomer, emailLists, isMobile, variantVersion);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const [hasError, setHasError] = useState(false);
  const [position, setPosition] = useState<SignUpDrawerPosition>('left');
  const [experience, setExperience] = useState<MinimizedButtonExperience>('minimized');
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (isMobile) {
      setPosition('bottom');
      setExperience('mobile');
    }
  }, [isMobile]);

  useMemo(() => checkIfValidEmail(emailAddress), [emailAddress]);

  // this callback is in place to allow time for error message to be displayed/cleared
  const validateEmail = useCallback(
    debounce(value => {
      setIsEmailValid(value === '' || checkIfValidEmail(value));
    }, EMAIL_VALIDATION_DELAY),
    []
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmailAddress(value);

    if (timer) {
      clearTimeout(timer);
    }

    /*
      This timer is necessary to clear the FIRST_TIME_SUBSCRIBER_DELAY message 
      after the user inputs something new. 
      Please refer to Figma for the various states of the helperText, 
      error messages, and placeholders.
      https://www.figma.com/design/B9OzfHcFq2aW6toVQ5fsBw/E-mail-Signup?t=sy1wam3rot9oUjw2-0
    */
    const newTimer = setTimeout(() => {
      setIsFirstTimeSubscriber(true);
    }, FIRST_TIME_SUBSCRIBER_DELAY);

    setTimer(newTimer);

    validateEmail(value);
  };

  /*
    The first return is part of the useEffect hook's implementation and sets up a cleanup function. Then inside cleanup function, check if timer exists, if it does, clear it out <- prevents memory leaks and unexpected behavior from lingering timers on re-renders
 */
  useEffect(
    () => () => {
      if (timer) {
        clearTimeout(timer);
      }
    },
    [timer]
  );

  // Cancel debounce on component unmount
  useEffect(
    () => () => {
      validateEmail.cancel();
    },
    [validateEmail]
  );

  const setLabelText = (isFocused: boolean, isEmailValid: boolean) => {
    if (isFocused) return EMAIL_STAR;
    return isEmailValid ? SIGN_UP_WITH_YOUR_EMAIL : EMAIL_ADDRESS;
  };

  const labelText: string = useMemo(() => setLabelText(isFocused, isEmailValid), [isFocused, isEmailValid]);

  const closeDrawerFinal: () => void = useCallback(() => {
    setIsMinimizedShown(false);
    setShouldRenderMinimized(false);
    setIsDrawerVisible(false);
    setIsDismissed(true);
    saveToLocalStorage(EMAIL_SIGNUP_DRAWER_LOCAL_STORAGE_KEY, {
      isDrawerVisible: false,
      isMinimizedShown: false,
      isSubmitted,
      isSubmitting,
      isDismissed: true
    });
  }, [isDrawerVisible, isMinimizedShown, isSubmitted, isSubmitting, isDismissed]);

  const closeDrawer: () => void = useCallback(() => {
    setExperience('minimized');
    if (isSubmitted) {
      setIsDrawerVisible(false);
      setIsMinimizedShown(false);
      setShouldRenderMinimized(false);
      setIsOpen(false);
      saveToLocalStorage(EMAIL_SIGNUP_DRAWER_LOCAL_STORAGE_KEY, {
        isDrawerVisible: false,
        isMinimizedShown: false,
        isSubmitted,
        isSubmitting
      });
    } else {
      setIsMinimizedShown(true);
      setIsOpen(false);
      setPosition('fromMinimized');
      setShouldRenderMinimized(true);
      saveToLocalStorage(EMAIL_SIGNUP_DRAWER_LOCAL_STORAGE_KEY, {
        isMinimizedShown: true
      });
    }
  }, [isDrawerVisible, isMinimizedShown, isSubmitted]);

  const openDrawer = useCallback(() => {
    setIsOpen(true);
    setIsMinimizedShown(false);
    setShouldRenderMinimized(false);
  }, [isDrawerVisible]);

  const checkboxClick = () => {
    setIsAccepted(true);
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsSubmitting(true);

    if (!isCustomer && !checkIfValidEmail(emailAddress)) {
      setIsEmailValid(false);
      setInvalidEmailMsg(PLEASE_ENTER_VALID_EMAIL);
      setIsSubmitting(false);
      return;
    }

    if (isCustomer && !isAccepted) {
      setIsAccepted(false);
      setIsSubmitting(false);
      return;
    }

    try {
      const finalEmailAddress: string | undefined = emailAddress || customerEmailAddress;
      const response = await dispatch(handleSubscribeSubmit(finalEmailAddress, 'emailSignupDrawer'));

      if (!isCustomer && response.isFirstTimeEmailSubscriber === false) {
        setIsFirstTimeSubscriber(false);
        setIsSubmitting(false);
        return;
      }

      const pageView = { pageView: { pageType: 'emailSignupDrawer' } };
      const source = { sourcePageType: 'emailSignupDrawer' };

      handleSubscribeSubmitted(pageView, source);
      setIsSubmitted(true);
      setIsSubmitting(false);
      saveToLocalStorage(EMAIL_SIGNUP_DRAWER_LOCAL_STORAGE_KEY, {
        isMinimizedShown: false,
        isSubmitted: true,
        isSubmitting: false
      });
    } catch (error) {
      setIsSubmitting(false);
      setHasError(true);
    }
  };

  return (
    showEmailSignupDrawer &&
    !isDismissed && (
      <SignUpDrawer
        invalidEmailMsg={invalidEmailMsg}
        isCustomer={isFetchedCustomer}
        isEmailValid={isEmailValid}
        isMinimizedShown={isMinimizedShown}
        isOpen={isOpen}
        isSubmitted={isSubmitted}
        isSubmitting={isSubmitting}
        labelText={labelText}
        isFirstTimeSubscriber={isFirstTimeSubscriber}
        handleFormSubmit={handleFormSubmit}
        handleChange={handleChange}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        openDrawer={openDrawer}
        closeDrawer={closeDrawer}
        closeDrawerFinal={closeDrawerFinal}
        checkboxClick={checkboxClick}
        testId={testId}
        hasError={hasError}
        isAccepted={isAccepted}
        position={position}
        experience={experience}
        shouldRenderMinimized={shouldRenderMinimized}
      />
    )
  );
};

export default SignUpDrawerWrapper;
