import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { trackError } from 'helpers/ErrorUtils';
import type { AppDispatch } from 'entrypoints/bootstrapOnClient';
import { fetchCustomerAuthDetails } from 'actions/authentication';
import { fetchAccountInfo } from 'actions/account/account';

const useFetchAccountInfo = () => {
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await dispatch(fetchCustomerAuthDetails());
        if (response.success) {
          dispatch(fetchAccountInfo());
        }
      } catch (error) {
        trackError('Error fetching customer auth details:', 'ERROR');
      }
    };

    fetchDetails();
  }, [fetchAccountInfo, fetchCustomerAuthDetails]);
};

export default useFetchAccountInfo;
