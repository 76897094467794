import {
  VISUAL_SEARCH_CAMERA_CLICK,
  VISUAL_SEARCH_CANCEL_IMAGE_UPLOAD,
  VISUAL_SEARCH_PAGE_VIEW,
  VISUAL_SEARCH_SUBMIT_IMAGE_SUCCESS,
  VISUAL_SEARCH_UPLOAD_IMAGE
} from 'constants/amethyst';
import { evProductInteract, evSwatchInteract } from 'events/search';
import { evProductClickThrough } from 'events/account';
import { PAGE_VIEW, PRODUCT_CLICK_THROUGH, PRODUCT_INTERACTION, SWATCH_INTERACTION } from 'constants/visualSearch';

type SubmitImageSuccessParams = {
  imageId: string;
};

export enum VisualSearchType {
  UNKNOWN_VISUAL_SEARCH_INPUT,
  FILE_SELECT,
  TAKE_PICTURE
}

type UploadImageParams = {
  isFileLimitExceed: boolean;
  fileSize: number;
  searchType: VisualSearchType;
};

export const pvVisualSearchPageView = () => ({
  [VISUAL_SEARCH_PAGE_VIEW]: {}
});

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/VisualSearchCameraClick.proto
 */
export const evVisualSearchCameraClick = () => ({
  [VISUAL_SEARCH_CAMERA_CLICK]: {}
});

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/VisualSearchCancelImageUpload.proto
 */
export const evVisualSearchCancelImageUpload = () => ({
  [VISUAL_SEARCH_CANCEL_IMAGE_UPLOAD]: {}
});

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/VisualSearchSubmitImageSuccess.proto
 *
 * @param {string} imageId - to Log the uploaded image.
 */
export const evVisualSearchSubmitImageSuccess = ({ imageId }: SubmitImageSuccessParams) => ({
  [VISUAL_SEARCH_SUBMIT_IMAGE_SUCCESS]: {
    imageId
  }
});

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/VisualSearchUploadImage.proto
 *
 * @param {number} fileSize - Size of the uploaded Image File
 * @param isFileLimitExceed - checks if the file size is greater than the limit
 * @param searchType - Upload, Take Picture or File Select
 */
// todo - currently setting as unknown searchType input. WIP - check if the camera captured images & file uploaded images can be differentiated
export const evVisualSearchUploadImage = ({
  fileSize,
  isFileLimitExceed,
  searchType = VisualSearchType.UNKNOWN_VISUAL_SEARCH_INPUT
}: UploadImageParams) => ({
  [VISUAL_SEARCH_UPLOAD_IMAGE]: {
    fileSize,
    isFileLimitExceed,
    searchType
  }
});

export default {
  pageEvent: VISUAL_SEARCH_PAGE_VIEW,
  events: {
    [PAGE_VIEW]: [pvVisualSearchPageView],
    [PRODUCT_INTERACTION]: [evProductInteract],
    [SWATCH_INTERACTION]: [evSwatchInteract],
    [PRODUCT_CLICK_THROUGH]: [evProductClickThrough]
  }
};
