import { SIXPM_MERCHANTID, ZAPPOS_MERCHANTID } from 'constants/crossSiteMerchantIdMapping';
import { arrayify } from 'helpers/index';
import { isSupportedMobileDevice } from 'helpers/UserAgentUtils';
import type { Marketplace } from 'types/app';

export const MARKETPLACE_URL_MAPPING = {
  'zappos': {
    devUrl: 'https://marty.zappos.com:9443',
    integUrl: 'https://beta.marty.zappos.com',
    preprodUrl: 'https://preprod-m.zappos.com',
    prodUrl: 'https://www.zappos.com'
  },
  '6pm': {
    devUrl: 'https://marty.6pm.com:8443',
    integUrl: 'https://beta.marty.6pm.com',
    preprodUrl: 'https://preprod-m.6pm.com',
    prodUrl: 'https://www.6pm.com'
  },
  'vrsnl.zappos': {
    devUrl: 'https://marty.vrsnl.zappos.com:12443',
    integUrl: 'https://beta.marty.vrsnl.zappos.com',
    preprodUrl: 'https://preprod-m.vrsnl.zappos.com',
    prodUrl: 'https://vrsnl.zappos.com'
  }
};

export const MERCHANTID_MARKET_NAME_MAPPING = {
  [ZAPPOS_MERCHANTID]: 'zappos',
  [SIXPM_MERCHANTID]: '6pm'
};

/**
 * Returns an entry or nested entry from marketplace. Key can be either an array
 * or a string.
 * @param  {object} marketplace
 * @param  {string} key
 * @return {object}
 */
export const entry = (marketplace: Marketplace, key: string) =>
  arrayify(key).reduce((acc: Marketplace, key: keyof Marketplace) => acc[key], marketplace);

/**
 * Return the platform for the given marketplace.
 * @param  {string} userAgent
 * @return {string}
 */
export const platform = (userAgent: string) => (isSupportedMobileDevice(userAgent) ? 'mobile' : 'desktop');

/**
 * Platform based entry configuration
 * @param  {object} marketplace
 * @param  {string} key
 * @param  {string} userAgent
 * @return {object}
 */
export const platformConfig = (marketplace: Marketplace, key: string, userAgent: string) => entry(marketplace, arrayify(key, platform(userAgent)));

/**
 * Return true/false if the given subsiteId is an allowed redirect subsite for
 * the given marketplace.
 * @param  {object}  marketplace
 * @param  {number}  subsiteId
 * @param  {string}  userAgent
 * @return {Boolean}
 */
export const isAllowedPreferredSubsite = (marketplace: Marketplace, subsiteId: string | number, userAgent: string) =>
  platformConfig(marketplace, 'preferredSubsites', userAgent).includes(parseInt(`${subsiteId}`));

/**
 * Returns absolute marketplace url if the passed a static domain string and host
 * @param {string} destination
 * @param {string} host
 * @return {string}
 */
export const getAbsoluteMarketplaceUrl = (destination: string, host: string) => {
  const marketplaceUrls = MARKETPLACE_URL_MAPPING[destination as keyof typeof MARKETPLACE_URL_MAPPING];
  if (!destination || !marketplaceUrls) {
    return '';
  }

  switch (true) {
    case host.includes('beta'):
      return marketplaceUrls.integUrl;
    case host.includes('preprod-m'):
      return marketplaceUrls.preprodUrl;
    case host.includes(':'):
      return marketplaceUrls.devUrl;
    default:
      return marketplaceUrls.prodUrl;
  }
};
