import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import type { Action, Location } from 'history';

import { selectIsPageTypeLoaded, selectPageType } from 'selectors/pageView';
import { selectIsEnrolled, selectIsRewardsInfoLoaded, selectRewardsDetermined } from 'selectors/rewards';
import { selectDirectedId, selectHolmesDetermined, selectHolmesLoaded } from 'selectors/holmes';
import { selectCartCount, selectIsCartCountLoaded } from 'selectors/cart';
import {
  CustomVarIndex,
  CustomVarKey,
  trackCustomVariable,
  trackDynamicVariable,
  trackPageEvent,
  trackPageview,
  USER_IDENTIFIER,
  VariableKeys
} from 'utils/contentSquare/uxaHelper';
import { loadScript } from 'helpers/ScriptUtils';
import usePrevious from 'hooks/usePrevious';

const IGNORE_ACTION = 'REPLACE';

interface LocationType extends Location {
  action?: Action;
}

const ContentSquare = () => {
  const directedId = useSelector(selectDirectedId);
  const pageType = useSelector(selectPageType);
  const isRewardsEnrolled = useSelector(selectIsEnrolled);
  const cartCount = useSelector(selectCartCount);
  const location = useLocation();
  const { action } = location as LocationType;
  // defaults to false if not loaded yet
  const isHolmesLoaded = useSelector(selectHolmesLoaded);
  const isPageTypeLoaded = useSelector(selectIsPageTypeLoaded);
  const isCartLoaded = useSelector(selectIsCartCountLoaded);
  const isRewardsInfoLoaded = useSelector(selectIsRewardsInfoLoaded);
  const isRewardsDetermined = useSelector(selectRewardsDetermined);
  const isHolmesDetermined = useSelector(selectHolmesDetermined);
  const previousPageType = usePrevious(pageType);

  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  const isAllCustomVarsLoaded =
    (isHolmesLoaded || isHolmesDetermined) && isPageTypeLoaded && isCartLoaded && (isRewardsInfoLoaded || isRewardsDetermined);
  const ignoredActionPages = ['product'];
  const isIgnoredAction = action === IGNORE_ACTION && ignoredActionPages.includes(pageType);


  const isMyAccRedesignedPage = ['my-returns', 'my-orders', 'item-details', 'return-item', 'return-info'].some(
    validLocation => location?.pathname.includes(validLocation)
  );

  const handleTrackingCallback = useCallback(() => {

    /** Wait for all custom variables to be available before sending pageView
     *  Ignore any app specific history changes ("REPLACE"), on certain pages
     *  - Style changes on PDP
     **/
    if (!isAllCustomVarsLoaded || isIgnoredAction) {
      return;
    }

    trackCustomVariable({
      index: CustomVarIndex.LOGGED_IN,
      key: CustomVarKey.LOGGED_IN,
      value: !!directedId
    });

    trackCustomVariable({
      index: CustomVarIndex.PAGE_TYPE,
      key: CustomVarKey.PAGE_TYPE,
      value: pageType
    });

    trackCustomVariable({
      index: CustomVarIndex.NO_OF_ITEMS_IN_CART,
      key: CustomVarKey.NO_OF_ITEMS_IN_CART,
      value: cartCount
    });

    trackCustomVariable({
      index: CustomVarIndex.LOYALTY_STATUS,
      key: CustomVarKey.LOYALTY_STATUS,
      value: Boolean(isRewardsEnrolled)
    });

    if (isMyAccRedesignedPage) {
      trackDynamicVariable({ key: VariableKeys.FEATURE_FLAG_KEY, value: 'myAccRedesign' });
    }

    if (directedId) {
      trackDynamicVariable({ key: VariableKeys.DIRECTED_ID_KEY, value: directedId });
      trackPageEvent(`${USER_IDENTIFIER}${directedId}`);
    }

    if (isScriptLoaded) {
      // Putting this check here to prevent pageview duplicates when we go:
      // search -> product -> back button
      if (pageType === previousPageType) {
        return;
      }

      trackPageview(location);
    } else {
      // order matters - We need to call this after the custom variables are set
      loadScript({ src: '/marty-assets/cs-container.js', onLoadCallback: () => setIsScriptLoaded(true) });
    }
  }, [isHolmesLoaded, isPageTypeLoaded, isCartLoaded, isRewardsInfoLoaded, cartCount, pageType, location]);

  useEffect(() => {
    if (typeof window !== 'undefined' && window?.requestIdleCallback) {
      // if we have the requestIdleCallback - lets use that to report to contentSquare
      // that way we don't block the input processing thread.
      window.requestIdleCallback(handleTrackingCallback);
    } else {
      // no requestIdleCallback -  we can use  setTimeout to defer it anyway. (Looking at you, Safari 17!)
      setTimeout(handleTrackingCallback, 5);
    }
  }, [handleTrackingCallback]);

  return <></>;
};

export default ContentSquare;
