// Influencer Page URLs
export const INFLUENCER_LANDING_PAGE = '/influencer-home';
export const INFLUENCER_ENROLL_PENDING = '/influencer/enroll/pending';
export const INFLUENCER_ENROLL_DECLINE = '/influencer/enroll/decline';
export const INFLUENCER_ENROLL_SUCCESS_PAGE = '/influencer/enroll/success';
export const INFLUENCER_ADD_PROFILE_CALLBACK_PAGE = '/influencer/callback';
export const INFLUENCER_SIGNUP_PAGE = '/influencer/signup';
export const INFLUENCER_ELIGIBILITY_PAGE = '/influencer/eligibility';
export const INFLUENCER_HUB_PAGE = '/influencer/hub';
export const INFLUENCER_ENROLL_CALLBACK = '/influencer/enroll';
export const INFLUENCER_SHOPPABLE_POST = '/influencer/hub/shoppablePost/';
export const INFLUENCER_SHOPPABLE_POST_CUSTOMER_VIEW = '/shoppablePost/';
export const INFLUENCER_COLLECTION = '/influencer/hub/collection/';
export const INFLUENCER_COLLECTION_CUSTOMER_VIEW = '/collection/';
export const INFLUENCER_STYLE_FEED_URL = '/style-feed';
export const SHOP_THE_LOOK_STYLE_FEED_URL = '/style-feed?filter=shopTheLook';
export const SHOP_THE_LOOK_FILTER_QUERY = 'shopTheLook';

// Media Links
export const INFLUENCER_COVER_IMAGE = 'https://m.media-amazon.com/images/G/01/zappos/zip/InfluencerCoverImage2.png';
export const COVER_IMAGE_ASPECT_RATIO = 5.38; // 1440 / 270
export const BRAND_INFLUENCER_LOGO = 'https://m.media-amazon.com/images/G/01/zappos/zip/BrandInfluencerLogo.png';
export const TAG_ICON = 'https://m.media-amazon.com/images/G/01/zappos/zip/Tag.png';
export const STYLE_FEED_BANNER_IMAGE = 'https://m.media-amazon.com/images/G/01/zappos/zip/StyleFeedBannerImage._SX2880_QL85_.jpg';
export const STYLE_FEED_HEADER_IMAGE = 'https://m.media-amazon.com/images/G/01/zappos/zip/InfluencerStyleFeed_HeaderImage.jpg';
// Influencer API names
export const FETCH_INFLUENCER_COLLECTION_REPORT = 'FETCH_INFLUENCER_COLLECTION_REPORT';
export const FETCH_INFLUENCER_PRODUCT_REPORT = 'FETCH_INFLUENCER_PRODUCT_REPORT';
export const FETCH_INFLUENCER_SUMMARY_REPORT = 'FETCH_INFLUENCER_SUMMARY_REPORT';
export const STYLE_FEED_HEADING = 'Style Feed';
export const INFLUENCER_HEADING = 'Influencer';

export const INFLUENCER_PAGES_DETAILS = {
  [INFLUENCER_LANDING_PAGE]: {
    pageName: 'influencer-home',
    isLandingPage: true,
    needsAuthentication: false
  },
  [INFLUENCER_SIGNUP_PAGE]: {
    pageName: 'influencer-enroll',
    isLandingPage: false,
    needsAuthentication: false
  },
  [INFLUENCER_ENROLL_SUCCESS_PAGE]: {
    pageName: 'influencer-enroll-success',
    isLandingPage: false,
    needsAuthentication: true
  },
  [INFLUENCER_ELIGIBILITY_PAGE]: {
    pageName: 'influencer-eligibility',
    isLandingPage: false,
    needsAuthentication: true
  },
  [INFLUENCER_ENROLL_PENDING]: {
    pageName: 'influencer-enroll-pending',
    isLandingPage: false,
    needsAuthentication: true
  },
  [INFLUENCER_ENROLL_DECLINE]: {
    pageName: 'influencer-enroll-decline',
    isLandingPage: false,
    needsAuthentication: true
  }
};

export const YOUTUBE_BASE_EMBEDED_URL = 'https://www.youtube.com/embed/';
export const INFLUENCER_MSA_FOLDER_PATH = 'https://m.media-amazon.com/images/G/01/zappos/zip/';

export const COLLECTION_DESCRIPTION_PROMPTS = [
  "What's your concept for this collection?",
  'How should your followers wear them?',
  'What occasion is this collection for?'
];
export const DESCRIPTION_PROMPT_DURATION_SECONDS = 20;

export const INFLUENCER_PROFILE_TAB = 'INFLUENCER_PROFILE_TAB';
export const INFLUENCERS_COLLECTIONS_TAB = 'INFLUENCER_COLLECTIONS_TAB';

// Others
export const INFLUENCER_NEW_COLLECTION_DAYS = 30;
export const STOREFRONT_CUSTOMER_VIEW = 'STOREFRONT_CUSTOMER_VIEW';
export const INITIAL_COLLECTION_COUNT = 6;
export const IDENTIFIER_TYPE_PROFILE_HANDLE = 'profileHandle';
export const IDENTIFIER_TYPE_CUSTOMER_ID = 'customerId';
export const VIDEO_GET_API_POLL_DELAY = 10000; // 10 seconds
export const SCRUTINIZER_SCAN_STATUS_GET_API_POLL_DELAY = 10000; // 10 seconds
export const INFLUENCER_COLLECTION_DEFAULT_LAYOUT = 1;
export const INFLUENCER_COLLECTION_IMAGE_LAYOUT = 2;
export const INFLUENCER_PROFILE_APPROVED_NO_CONTENT = 'approved_no_content';

export const NEW_COLLECTION_CONTENT = {
  heading: 'Create New Collection',
  label: 'Collection Name',
  hydraLabel: 'Name your Collection',
  markInfluencer: true
};

export const DELETE_COLLECTION_CONTENT = {
  heading: 'Delete Collection',
  message: 'Are you sure you want to delete this Collection?'
};

// Collection Filters
export const INFLUENCER_PUBLISHED_COLLECTIONS = 'INFLUENCER_PUBLISHED_COLLECTIONS';
export const INFLUENCER_ALL_COLLECTIONS = 'INFLUENCER_ALL_COLLECTIONS';
export const INFLUENCER_HIDDEN_COLLECTIONS = 'INFLUENCER_HIDDEN_COLLECTIONS';
export const INFLUENCER_PENDING_REVIEW_COLLECTIONS = 'INFLUENCER_PENDING_REVIEW_COLLECTIONS';
export const INFLUENCER_DECLINED_COLLECTIONS = 'INFLUENCER_DECLINED_COLLECTIONS';
export const INFLUENCER_DRAFT_COLLECTIONS = 'INFLUENCER_DRAFT_COLLECTIONS';

// Shoppable Post Filters
export const INFLUENCER_ALL_SHOPPABLE_POSTS = 'INFLUENCER_ALL_SHOPPABLE_POSTS';
export const INFLUENCER_HIDDEN_SHOPPABLE_POSTS = 'INFLUENCER_HIDDEN_SHOPPABLE_POSTS';
export const INFLUENCER_DRAFT_SHOPPABLE_POSTS = 'INFLUENCER_DRAFT_SHOPPABLE_POSTS';
export const INFLUENCER_PENDING_REVIEW_SHOPPABLE_POSTS = 'INFLUENCER_PENDING_REVIEW_SHOPPABLE_POSTS';
export const INFLUENCER_DECLINED_SHOPPABLE_POSTS = 'INFLUENCER_DECLINED_SHOPPABLE_POSTS';
export const INFLUENCER_READY_TO_SHARE_SHOPPABLE_POSTS = 'INFLUENCER_READY_TO_SHARE_SHOPPABLE_POSTS';
