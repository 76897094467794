import {
  BEGIN_FETCH_SYMPHONY_SEARCH_COMPONENTS,
  FETCH_SYMPHONY_SEARCH_COMPONENTS_ERROR,
  RECEIVE_SYMPHONY_SEARCH_COMPONENTS
} from 'store/ducks/search/types';

const initialState = {
  error: null,
  isLoadingSymphony: false,
  symphony: {}
};

export default function searchReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case BEGIN_FETCH_SYMPHONY_SEARCH_COMPONENTS:
      return { ...state, isLoadingSymphony: true };
    case RECEIVE_SYMPHONY_SEARCH_COMPONENTS:
      return {
        ...state,
        isLoadingSymphony: false,
        symphony: payload.data
      };
    case FETCH_SYMPHONY_SEARCH_COMPONENTS_ERROR:
      return { ...state, isLoadingSymphony: false, error: payload.error };
    default:
      return state;
  }
}
