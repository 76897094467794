import { SET_RESIZE } from './actions';

import type { DevicePropsState } from 'store/ducks/deviceProps/types';

const initialState: DevicePropsState = {
  viewportWidth: null,
  viewportHeight: null,
  screenWidth: null,
  screenHeight: null
};

// Reducer
const reducer = (state = initialState, action: any): DevicePropsState => {
  switch (action.type) {
    case SET_RESIZE:
      return {
        ...state,
        viewportWidth: action.payload.viewportWidth,
        viewportHeight: action.payload.viewportHeight,
        screenWidth: action.payload.screenWidth,
        screenHeight: action.payload.screenHeight
      };
    default:
      return state;
  }
};

export default reducer;
