import { RETURN_LABEL_TYPE } from 'constants/amethystEnums';

const determineReturnLabelTypes = returnLabelTypes => returnLabelTypes.map(type => determineReturnLabelType(type));

const determineReturnLabelType = returnLabelType => RETURN_LABEL_TYPE[returnLabelType] || 'UNKNOWN_RETURN_LABEL_TYPE';

export const evReturnInitiateClick = ({ returnInitiateSource }) => ({
  returnInitiateClick: {
    returnInitiateSource: returnInitiateSource
  }
});

export const evNumberOfBoxesOnReturnSubmit = ({ numberOfBoxes }) => ({
  numberOfBoxesOnReturnSubmit: {
    numberOfBoxes: numberOfBoxes
  }
});

export const evReturnTransportationOptions = ({ availableReturnLabels, displayedReturnLabels, arePickupReturnsAuthorized }) => ({
  returnTransportationOptions: {
    availableReturnLabelTypes: determineReturnLabelTypes(availableReturnLabels),
    displayedReturnLabelTypes: determineReturnLabelTypes(displayedReturnLabels),
    upsPickupsAuthorized: arePickupReturnsAuthorized
  }
});

export const evZawReturn = ({ step, scenario, subsidyValue }) => {
  const event = {
    zawReturn: {
      step,
      scenario
    }
  };

  if (subsidyValue !== undefined && subsidyValue !== null) {
    event.zawReturn.subsidyValue = subsidyValue;
  }

  return event;
};

export const evSubmitReturnClick = ({ returnLabel, startTime }) => {
  const MILLISECOND_TO_SECOND = 1000;
  const calculateTimeSpentInSecond = startTime => Math.floor((new Date().getTime() - startTime) / MILLISECOND_TO_SECOND);
  return {
    submitReturnClick: {
      returnLabelType: [determineReturnLabelType(returnLabel)],
      returnDetails: [{ timeSpent: calculateTimeSpentInSecond(startTime) }]
    }
  };
};

export const pvReturnConfirmation = ({ returnLabel }) => ({
  returnConfirmationPageView: {
    returnLabelType: determineReturnLabelType(returnLabel)
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/MyOrdersStartReturnClick.proto
export const evMyOrdersStartReturnClick = () => ({
  myOrdersStartReturnClick: {}
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ItemDetailsStartReturnClick.proto
export const evItemDetailsStartReturnClick = () => ({
  itemDetailsStartReturnClick: {}
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/OrderDetailsStartReturnClick.proto
export const evOrderDetailsStartReturnClick = () => ({
  orderDetailsStartReturnClick: {}
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/AddMoreItemsToThisReturnClick.proto
export const evAddMoreItemsToThisReturnClick = () => ({
  addMoreItemsToThisReturnClick: {}
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/MyReturnsStartReturnClick.proto
export const evMyReturnsStartReturnClick = () => ({
  myReturnsStartReturnClick: {}
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ReturnMultiItemSelectBoxesClick.proto
export const evReturnMultiItemSelectBoxesClick = ({ numberOfItems, numberOfBoxes }) => ({
  returnMultiItemSelectBoxesClick: {
    numberOfItems,
    numberOfBoxes
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ReturnMultiItemToNumberOfBoxesClick.proto
export const evReturnMultiItemToNumberOfBoxesClick = ({ numberOfItems }) => ({
  returnMultiItemToNumberOfBoxesClick: {
    numberOfItems
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ReturnSingleItemClick.proto
export const evReturnSingleItemClick = () => ({
  returnSingleItemClick: {}
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ReturnSingleItemToReturnMethodClick.proto
export const evReturnSingleItemToReturnMethodClick = () => ({
  returnSingleItemToReturnMethodClick: {}
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ReturnSingleItemToSummaryReturnClick.proto
export const evReturnSingleItemToSummaryReturnClick = () => ({
  returnSingleItemToSummaryReturnClick: {}
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ReturnSingleItemConfirmReturnClick.proto
export const evReturnSingleItemConfirmReturnClick = () => ({
  returnSingleItemConfirmReturnClick: {}
});

export const evSelectMultiItemClick = () => ({
  selectMultiItemClick: {}
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ReturnMultiItemClick.proto
export const evReturnMultiItemClick = () => ({
  returnMultiItemClick: {}
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ReturnMultiItemToReturnMethodClick.proto
export const evReturnMultiItemToReturnMethodClick = ({ numberOfItems }) => ({
  returnMultiItemToReturnMethodClick: {
    numberOfItems
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ReturnMultiItemToSummaryReturnClick.proto
export const evReturnMultiItemToSummaryReturnClick = ({ numberOfItems, numberOfBoxes }) => ({
  returnMultiItemToSummaryReturnClick: {
    numberOfItems,
    numberOfBoxes
  }
});

// https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/ReturnMultiItemConfirmReturnClick.proto
export const evReturnMultiItemConfirmReturnClick = ({ numberOfItems, numberOfBoxes }) => ({
  returnMultiItemConfirmReturnClick: {
    numberOfItems,
    numberOfBoxes
  }
});
